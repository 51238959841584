import { createAction } from 'typesafe-actions';
import { CORE_ACTIONS } from '../../action-types';

export const setAuthenticatedAction = createAction(
  CORE_ACTIONS.AUTH,
  (accessToken: string | null) => ({
    accessToken,
  }),
)();

export const setIsLogged = createAction(CORE_ACTIONS.SET_IS_LOGGED, (isLogged: boolean) => ({
  isLogged,
}))();

export const setHostAction = createAction(CORE_ACTIONS.SET_HOST, (host: string | null) => ({
  host,
}))();

export const setStoreHostAction = createAction(
  CORE_ACTIONS.SET_SHOP,
  (storeHost: string | null) => ({
    storeHost,
  }),
)();

export const setRolesAction = createAction(CORE_ACTIONS.SET_ROLES, (roles: string[] | null) => ({
  roles,
}))();

export const setStoreActivityAction = createAction(
  CORE_ACTIONS.SET_STORE_ACTIVITY,
  (inactiveStore: boolean) => ({
    inactiveStore,
  }),
)();
