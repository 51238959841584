import { useSelector } from 'react-redux';
import { getStoreHostSelector } from '../../../redux/modules/auth/auth.selectors';
import { AppTextStyle } from '../../text/text-style/TextStyle';

type LinkToPlatformProps = {
  visible: boolean;
  platformId: number;
};

export const LinkToPlatform = ({ visible, platformId }: LinkToPlatformProps) => {
  const storeHost = useSelector(getStoreHostSelector);
  return (
    <span
      className={`link-to-platform${visible ? ' visible' : ''}`}
      onClick={(e) => {
        e.stopPropagation();
        window.open(`https://${storeHost}/admin/products/${platformId}`);
      }}
    >
      <AppTextStyle variation="subdued">View on Shopify</AppTextStyle>
    </span>
  );
};
