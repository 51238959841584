import { createReducer } from 'typesafe-actions';
import { IPreferences } from '../../../interfaces/IPreferences';
import { RootActions } from '../../actions';
import {
  getPreferencesAction,
  hideAddressValidationFailedToastAction,
  hideAddressValidationSuccessToastAction,
  setPreferencesAction,
  setPreferencesFromApiAction,
  showAddressValidationFailedToastAction,
} from './preferences.actions';

interface IPreferencesReducer {
  preferences: IPreferences | null;
  fetching: boolean;
  showAddressValidationFailedToast: boolean;
  showAddressValidationSuccessToast: boolean;
}

const initialState: IPreferencesReducer = {
  preferences: null,
  fetching: false,
  showAddressValidationFailedToast: false,
  showAddressValidationSuccessToast: false,
};

export const preferencesReducer = createReducer<IPreferencesReducer, RootActions>(initialState)
  .handleAction(getPreferencesAction, (state) => ({ ...state, fetching: true }))
  .handleAction(setPreferencesFromApiAction, (state, { payload }) => ({
    ...state,
    fetching: false,
    preferences: {
      ...payload,
      product: {
        MSRP: {
          ...payload.product.MSRP,
          adjustment: payload.product.MSRP.adjustment && {
            ...payload.product.MSRP.adjustment,
            amount: payload.product.MSRP.adjustment.amount.toString(),
          },
          salePriceAdjustment: payload.product.MSRP.salePriceAdjustment && {
            ...payload.product.MSRP.salePriceAdjustment,
            amount: payload.product.MSRP.salePriceAdjustment.amount.toString(),
          },
        },
        dropShipCost: {
          ...payload.product.dropShipCost,
          adjustment: payload.product.dropShipCost.adjustment && {
            ...payload.product.dropShipCost.adjustment,
            amount: payload.product.dropShipCost.adjustment.amount.toString(),
          },
          salePriceAdjustment: payload.product.dropShipCost.salePriceAdjustment && {
            ...payload.product.dropShipCost.salePriceAdjustment,
            amount: payload.product.dropShipCost.salePriceAdjustment.amount.toString(),
          },
        },
        updatePrices: payload.product.updatePrices.toString() as 'true' | 'false',
      },
      order: {
        ...payload.order,
        cancellationAcceptThreshold:
          payload.order.cancellationAcceptThreshold &&
          payload.order.cancellationAcceptThreshold.toString(),
      },
      visibility: {
        marketplace: {
          mode: payload.visibility.marketplace.mode,
          autoApprove: payload.visibility.marketplace.autoApprove.toString() as 'true' | 'false',
          list: payload.visibility.marketplace.list.map((e) => ({
            value: e._id,
            label: e.name,
          })),
        },
        privateNetwork: payload.visibility.privateNetwork.map((e) => ({
          retailer: {
            value: e.retailer._id,
            label: e.retailer.name,
          },
          paidViaCrowdship: e.paidViaCrowdship ? 'true' : 'false',
          customerId: e.customerId || '',
          handlingFee: typeof e.handlingFee === 'number' ? e.handlingFee.toFixed(2) : '',
          orderPrefix: e.orderPrefix || '',
          marketplaceAccessStatus: e.marketplaceAccessStatus,
          tags: e.tags || '',
        })),
      },
      shipping: {
        ...payload.shipping,
        handlingFee: payload.shipping.handlingFee
          ? payload.shipping.handlingFee.toFixed(2)
          : '0.00',
        useCustomWarehouseAddress: payload.shipping.useCustomWarehouseAddress
          ? 'use custom'
          : 'use default',
        warehouseAddress: {
          address1: payload.shipping.warehouseAddress?.address1 || '',
          address2: payload.shipping.warehouseAddress?.address2 || '',
          city: payload.shipping.warehouseAddress?.city || '',
          country: 'United States',
          countryCode: 'US',
          province: payload.shipping.warehouseAddress?.province || '',
          provinceCode: payload.shipping.warehouseAddress?.provinceCode || '',
          zip: payload.shipping.warehouseAddress?.zip || '',
        },
        useCustomFallbackAddress: payload.shipping.useCustomFallbackAddress
          ? 'use custom'
          : 'use default',
        fallbackAddress: {
          address1: payload.shipping.fallbackAddress?.address1 || '',
          address2: payload.shipping.fallbackAddress?.address2 || '',
          city: payload.shipping.fallbackAddress?.city || '',
          country: payload.shipping.fallbackAddress?.country || 'United States',
          countryCode: payload.shipping.fallbackAddress?.countryCode || 'US',
          province: payload.shipping.fallbackAddress?.province || '',
          provinceCode: payload.shipping.fallbackAddress?.provinceCode || '',
          zip: payload.shipping.fallbackAddress?.zip || '',
        },
        shippingTypesMapping: payload.shipping.shippingTypesMapping.map((stm) => ({
          ...stm,
          startDeliveryTime: stm.startDeliveryTime?.toString() || '',
          finishDeliveryTime: stm.finishDeliveryTime?.toString() || '',
          customShippingTypeName: stm.customShippingTypeName || '',
          flatRateCost: stm.flatRateCost ? (+stm.flatRateCost).toFixed(2) : '0',
        })),
      },
      payout: {
        ...payload.payout,
        achDetails: {
          ...payload.payout?.achDetails,
          confirmAccount: payload.payout?.achDetails?.account,
        },
      },
    },
  }))
  .handleAction(showAddressValidationFailedToastAction, (state) => ({
    ...state,
    showAddressValidationFailedToast: true,
    validatingAddress: false,
  }))
  .handleAction(hideAddressValidationFailedToastAction, (state) => ({
    ...state,
    showAddressValidationFailedToast: false,
  }))
  .handleAction(hideAddressValidationSuccessToastAction, (state) => ({
    ...state,
    showAddressValidationSuccessToast: false,
  }))
  .handleAction(setPreferencesAction, (state, { payload }) => ({
    ...state,
    preferences: {
      ...payload,
    },
  }));
