import { List } from '@shopify/polaris';
import { QuestionCircleIcon } from '@shopify/polaris-icons';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useHistory } from 'react-router';
import { AppBadge } from '../../../../core/components/badge/badge';
import { AppButton } from '../../../../core/components/button/Button';
import { AppToast } from '../../../../core/components/feedback-indicators/toast/toast';
import { AppIcon } from '../../../../core/components/icon/icon';
import { AppThumbnail } from '../../../../core/components/image-containers/thumbnail/Thumbnail';
import { AppIndexTable } from '../../../../core/components/index-table/index-table';
import { AppLink } from '../../../../core/components/link/link';
import { ShippingTooltip } from '../../../../core/components/shipping-tooltip/shipping-tooltip';
import { AppCard } from '../../../../core/components/structure/card/card';
import { AppTextStyle } from '../../../../core/components/text/text-style/TextStyle';
import { withCurrency } from '../../../../core/helpers/currency.helper';
import { getStoreHostSelector } from '../../../../core/redux/modules/auth/auth.selectors';
import {
  IMarketplaceProductDetails,
  MARKETPLACE_ACCESS_STATUS,
} from '../../../interfaces/IProduct';
import {
  addMarketplaceProductsToImportListAction,
  hideImportListProductsAddedToastAction,
} from '../../../redux/modules/marketplace/marketplace.actions';
import { showProductsAddedToImportListToastSelector } from '../../../redux/modules/marketplace/marketplace.selectors';
import { getStatusAction } from '../../../redux/modules/status/status.actions';
import { getStatusSelector } from '../../../redux/modules/status/status.selectors';
import '../product-details/product-details.scss';
import { BuilderVariantListRow } from '../variant-list/builder/builder-variant-list-row';
import { VariantConnectionModal } from '../../modals/variant-connection-modal/variant-connection-modal';
import { marketplaceVariantHeadings } from '../variant-list/builder/builder.constants';
import { isProductApproved } from '../../../../core/helpers/product-request.helper';
import { RequestApprovalWrapper } from '../../../../core/components/request-approval/request-approval-wrapper/request-approval-wrapper';

import './marketplace-product-details.scss';

type ProductDetailsProps = {
  product: IMarketplaceProductDetails;
  setProduct: (product: IMarketplaceProductDetails) => void;
};

type ItemLinks =
  | {
      retailerProductId?: number | undefined;
      retailerProductGroup?: string | undefined;
    }
  | undefined;

export const MarketplaceProductDetails = ({ product, setProduct }: ProductDetailsProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const storeHost = useSelector(getStoreHostSelector);
  const status = useSelector(getStatusSelector);
  const productsAdded = useSelector(showProductsAddedToImportListToastSelector);

  const [accessStatusState, setAccessStatusState] = useState(product.accessStatus);

  const [prices] = useState(product.variants.map((v) => +v.priceForRetailers));
  const [MSRPs] = useState(product.variants.map((v) => +v.MSRP));
  const [links] = useState(
    product.variants
      .map((v) => v.connectedProductsIds)
      .flat()
      .filter((cPI) => cPI?.retailerProductGroup && cPI?.retailerProductId)
      .reduce((accumulator, currentValue) => {
        if (
          accumulator.every(
            (item: ItemLinks) =>
              !(
                item?.retailerProductGroup === currentValue?.retailerProductGroup &&
                item?.retailerProductId === currentValue?.retailerProductId
              ),
          )
        )
          accumulator.push(currentValue);
        return accumulator;
      }, [] as ItemLinks[]),
  );
  const [expanded, setExpanded] = useState(false);

  const handleCreateRequest = useCallback(() => {
    setAccessStatusState(MARKETPLACE_ACCESS_STATUS.REQUESTED);
  }, [setAccessStatusState]);

  const hideProductsAddedToast = useCallback(() => {
    dispatch(hideImportListProductsAddedToastAction());
  }, [dispatch]);

  const linksSection = useMemo(
    () =>
      links.length > 0 && (
        <>
          <div className="add-button">
            <span style={{ fontSize: 'small' }}>
              Warning: Importing this product will create a duplicate in your store. You're already
              selling at least one variant of this product.
            </span>
          </div>
          <div className="add-button" style={{ marginBottom: '6px' }}>
            <List type="number">
              {links.slice(0, expanded ? links.length : 3).map((cPI) => (
                <List.Item>
                  <span style={{ fontSize: 'small' }}>
                    <AppLink url={`/my-products/${cPI?.retailerProductGroup}`} monochrome>
                      View Crowdship product
                    </AppLink>
                    &nbsp;--&nbsp;
                    <a
                      href={`https://${storeHost}/admin/products/${cPI?.retailerProductId}`}
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: 'inherit' }}
                    >
                      View Shopify Listing
                    </a>
                  </span>
                </List.Item>
              ))}
            </List>
          </div>
          {links.length > 3 && (
            <div>
              <span
                style={{ fontSize: 'small', cursor: 'pointer', color: '#0000FF' }}
                onClick={(e) => {
                  e.stopPropagation();
                  setExpanded(!expanded);
                }}
              >
                {expanded ? 'View less' : 'View more'}
              </span>
            </div>
          )}
        </>
      ),
    [expanded, links, storeHost],
  );
  const disableAdding = useMemo(
    () =>
      status && status.productsLimit !== undefined && status.productsCount >= status.productsLimit,
    [status],
  );
  const [pendingConnect, setPendingConnect] = useState<string | undefined>();

  const [variantConnected, setVariantConnected] = useState(false);
  const [addedToStore, setAddedToStore] = useState(false || product.isAdded);
  const [errorWhenConnecting, setErrorWhenConnecting] = useState('');
  const handleSuccessConnection = useCallback(
    (variantId) => {
      setVariantConnected(true);
      setProduct({
        ...product,
        variants: product.variants.map((v) =>
          v.id === variantId ? { ...v, connectedProducts: ++v.connectedProducts } : v,
        ),
      });
      setPendingConnect(undefined);
      dispatch(getStatusAction());
    },
    [dispatch, product, setProduct],
  );

  const connectionModalMarkup = useMemo(() => {
    if (!pendingConnect) return null;
    return (
      <VariantConnectionModal
        crowdshipProduct={pendingConnect}
        onClose={() => setPendingConnect(undefined)}
        onSuccess={handleSuccessConnection}
        onError={(error: string) => {
          setErrorWhenConnecting(error);
          setPendingConnect(undefined);
        }}
      />
    );
  }, [pendingConnect, handleSuccessConnection]);

  const handleProductDisconnect = useCallback(
    (variantId: string) => {
      setProduct({
        ...product,
        variants: product.variants.map((v) => {
          return v.id === variantId ? { ...v, connectedProducts: --v.connectedProducts } : v;
        }),
      });
    },
    [product, setProduct],
  );

  const handleAddToImportList = useCallback(() => {
    dispatch(addMarketplaceProductsToImportListAction([product.id], false));
    setAddedToStore(true);
  }, [dispatch, product.id]);

  return (
    <>
      <AppCard
        sections={[
          {
            content: (
              <div className="product-level-section">
                <div className="carousel-section">
                  <div className="product-image">
                    {product.images.length ? (
                      <Carousel
                        showThumbs={true}
                        showArrows={true}
                        swipeable={true}
                        showIndicators={false}
                      >
                        {product.images.map((image) => (
                          <div>
                            <img src={image.src} alt={product.title} />
                          </div>
                        ))}
                      </Carousel>
                    ) : (
                      <AppThumbnail alt={product.title} size="large" />
                    )}
                  </div>
                  <div className="near-carousel-section">
                    <div className="product-title">
                      <AppTextStyle variation="strong">{product.title}</AppTextStyle>
                      <span style={{ fontSize: 'small', fontWeight: 'normal' }}>
                        by {product.vendor}
                      </span>
                    </div>
                    <div className="fulfilled-by">
                      <span style={{ fontSize: 'small' }}>Fulfilled By:&nbsp;</span>
                      <span style={{ fontSize: 'small', textDecoration: 'underline' }}>
                        {product.supplierName}
                      </span>
                    </div>
                    <div className="b2b-msrp-wrapper">
                      <div className="b2b">
                        <AppTextStyle variation="strong">B2B Cost:</AppTextStyle>
                        <RequestApprovalWrapper
                          supplierId={product.supplierId}
                          accessStatus={accessStatusState}
                          handleCreateRequest={handleCreateRequest}
                        >
                          <span>
                            {`${withCurrency(Math.min(...prices))}${
                              Math.min(...prices) !== Math.max(...prices) ? '+' : ''
                            }`}
                          </span>
                        </RequestApprovalWrapper>
                      </div>
                      <div className="msrp">
                        <AppTextStyle variation="strong">SRP:</AppTextStyle>
                        <span>
                          {`${withCurrency(Math.min(...MSRPs))}${
                            Math.min(...MSRPs) !== Math.max(...MSRPs) ? '+' : ''
                          }`}
                        </span>
                      </div>
                    </div>
                    <div className="product-type">
                      <AppTextStyle variation="strong">Product Type:&nbsp;</AppTextStyle>
                      <span>{product.productType}</span>
                    </div>
                    <div className="product-type">
                      <AppTextStyle variation="strong">Product Category:&nbsp;</AppTextStyle>
                      <span>{product.productCategory}</span>
                    </div>
                    <div className="product-type">
                      <AppTextStyle variation="strong">Ships to:&nbsp;</AppTextStyle>
                      <span className="tooltip-wrapper">
                        Worldwide{' '}
                        <ShippingTooltip
                          shippingTypesMapping={product.shipping.shippingTypesMapping}
                          location={product.location}
                          variants={product.variants}
                        >
                          <span className="hover">
                            <AppIcon source={QuestionCircleIcon} color="warning" />
                          </span>
                        </ShippingTooltip>
                      </span>
                    </div>
                    {product.postageSetup === 'crowdship' && (
                      <p>This Supplier will include a branded packing slip.</p>
                    )}
                    <div className="add-button">
                      <AppButton
                        disabled={addedToStore || !isProductApproved(product.accessStatus)}
                        handleClick={(e) => {
                          e.stopPropagation();
                          handleAddToImportList();
                        }}
                      >
                        {addedToStore ? 'Added!' : 'Add to Import list'}
                      </AppButton>
                    </div>
                    {linksSection}
                  </div>
                </div>
                <div>
                  <p style={{ marginBottom: '20px' }}>
                    {
                      // eslint-disable-next-line no-useless-escape
                      product.description?.replace(/(\<(\/?[^>]+)>)/g, '') || ''
                    }
                  </p>
                </div>
                <div>
                  <AppTextStyle variation="strong">Tags:</AppTextStyle>
                  <br />
                  {!!product.tags.length ? (
                    product.tags.split(',').map((tag) => <AppBadge>{tag}</AppBadge>)
                  ) : (
                    <AppTextStyle>No tags</AppTextStyle>
                  )}
                </div>
              </div>
            ),
          },
          {
            title: 'Variants',
            content: (
              <div className="variant-level-section">
                <AppIndexTable
                  selectable={false}
                  resourceName={{ singular: 'variant', plural: 'variants' }}
                  headings={marketplaceVariantHeadings}
                  itemCount={product.variants.length}
                >
                  {product.variants.map((variant, index) => (
                    <BuilderVariantListRow
                      variant={{
                        ...variant,
                        supplierId: product.supplierId,
                        accessStatus: accessStatusState,
                      }}
                      index={index}
                      disableAdding={disableAdding}
                      onConnect={setPendingConnect}
                      handleCreateRequest={handleCreateRequest}
                      onDisconnect={handleProductDisconnect}
                      selected={false}
                      showConnectedProductsLinks
                    />
                  ))}
                </AppIndexTable>
              </div>
            ),
          },
        ]}
      />
      {connectionModalMarkup}
      {variantConnected && (
        <AppToast
          content="Variant successfully connected"
          onDismiss={() => setVariantConnected(false)}
        />
      )}
      {errorWhenConnecting && (
        <AppToast
          content={errorWhenConnecting}
          onDismiss={() => setErrorWhenConnecting('')}
          error
        />
      )}
      {productsAdded && (
        <AppToast
          onDismiss={hideProductsAddedToast}
          content="Products added"
          action={{
            accessibilityLabel: 'Go to Import List',
            content: 'Go to Import List',
            onAction: () => history.push('/import-list'),
          }}
        />
      )}
    </>
  );
};
