import { useIndexResourceState } from '@shopify/polaris';
import { SelectionType } from '@shopify/polaris/build/ts/latest/src/utilities/index-provider/types';
import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { AppChoiceList } from '../../../../../core/components/choice-list/ChoiceList';
import { AppFilters, IAppliedFilter } from '../../../../../core/components/filters/filters';
import { AppIndexTable } from '../../../../../core/components/index-table/index-table';
import { PageAwarePagination } from '../../../../../core/components/pagination/page-aware-pagination';
import { AppCard } from '../../../../../core/components/structure/card/card';
import { PRODUCT_REMOVE_FROM_CROWDSHIP_OPTION } from '../../../../interfaces/IPreferences';
import {
  IProductMatch,
  IProductSyncMatchFilter,
  ISupplierProductMatchMissingVariant,
} from '../../../../interfaces/IProductsSync';
import { MatchVariantList } from '../match-variant-list/match-variant-list';
import './sync-match-list.scss';
import { SyncMatch } from './sync-match/sync-match';

interface IMatchPrices {
  [shopifyId: string]: {
    [sku: string]: {
      price: string;
      option: PRODUCT_REMOVE_FROM_CROWDSHIP_OPTION;
    };
  };
}

interface ISyncMatchListProps {
  storeHost: string;
  vendors: string[];
  linkMatches: (matches: IProductMatch[]) => void;
  linkAllMatches: (filter: IProductSyncMatchFilter) => void;
  matches: IProductMatch[];
  pageCount: number;
  onPageChange: (page: number, filter: IProductSyncMatchFilter) => void;
  onFilterChange: (filter: IProductSyncMatchFilter) => void;
  limit?: number;
  onLimitReachedStatusChange: (status: boolean) => void;
  onLimitExceededStatusChange: (status: boolean) => void;
  loading: boolean;
}

export function SyncMatchList({
  storeHost,
  vendors,
  linkMatches,
  linkAllMatches,
  matches,
  pageCount,
  onPageChange,
  onFilterChange,
  limit,
  onLimitExceededStatusChange,
  onLimitReachedStatusChange,
  loading,
}: ISyncMatchListProps) {
  const [queryValue, setQueryValue] = useState<string>('');
  const [selectedVendors, setSelectedVendors] = useState<string[]>([]);
  const [expandedVariants, setExpandedVariants] = useState<string[]>([]);

  useEffect(() => {
    // Collapse variants on products change/on form submit
    setExpandedVariants([]);
  }, [matches]);

  const vendorOptions = useMemo(() => vendors.map((v) => ({ label: v, value: v })), [vendors]);
  const debouncedOnFilterChange = useDebouncedCallback(() => {
    onFilterChange({ title: queryValue, vendors: selectedVendors });
  }, 500);

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(matches);

  const disableAdding = useMemo(
    () => limit !== undefined && limit <= selectedResources.length,
    [selectedResources, limit],
  );
  const disableSubmit = useMemo(
    () => limit !== undefined && limit < selectedResources.length,
    [selectedResources, limit],
  );

  useEffect(() => {
    onLimitReachedStatusChange(disableAdding);
  }, [disableAdding, onLimitReachedStatusChange]);
  useEffect(() => {
    onLimitExceededStatusChange(disableSubmit);
  }, [disableSubmit, onLimitExceededStatusChange]);

  const prices: IMatchPrices = useMemo(
    () =>
      matches.reduce((total: IMatchPrices, { retailerProduct: rp, supplierProduct: sp }) => {
        const missingVariants = sp.variants.filter(
          (sv) => sv.isMissing,
        ) as ISupplierProductMatchMissingVariant[];
        const mappedMissingVariants: {
          [sku: string]: {
            price: string;
            option: PRODUCT_REMOVE_FROM_CROWDSHIP_OPTION;
          };
        } = missingVariants.reduce(
          (svtotal, sv) => ({
            ...svtotal,
            [sv.sku]: {
              price: sv.price,
            },
          }),
          {},
        );
        return {
          ...total,
          [rp.shopifyId.toString()]: rp.variants.reduce(
            (rvtotal, rv) => ({
              ...rvtotal,
              [rv.sku]: {
                price: rv.price,
                option: rv.deleteOption,
              },
            }),
            mappedMissingVariants,
          ),
        };
      }, {}),
    [matches],
  );

  const toggleExpansion = useCallback(
    (shopifyId: string) => {
      if (expandedVariants.includes(shopifyId))
        setExpandedVariants((items) => items.filter((i) => i !== shopifyId));
      else {
        setExpandedVariants((items) => items.concat([shopifyId]));
      }
    },
    [expandedVariants],
  );

  const handlePageChange = useCallback(
    (newPage: number) => onPageChange(newPage, { title: queryValue, vendors: selectedVendors }),
    [onPageChange, queryValue, selectedVendors],
  );

  const handleSelectedVendorsChange = useCallback(
    (vendors: string[]) => {
      setSelectedVendors(vendors);
      onFilterChange({ title: queryValue, vendors });
    },
    [onFilterChange, queryValue],
  );

  const handleSelectedVendorsRemove = useCallback(() => {
    setSelectedVendors([]);
    onFilterChange({ title: queryValue, vendors: [] });
  }, [onFilterChange, queryValue]);

  const handleQueryValueRemove = useCallback(() => {
    setQueryValue('');
    onFilterChange({ title: '', vendors: selectedVendors });
  }, [onFilterChange, selectedVendors]);

  const handleFiltersQueryChange = (value: string) => {
    setQueryValue(value);
    debouncedOnFilterChange();
  };

  const handleFiltersClearAll = useCallback(() => {
    setSelectedVendors([]);
    setQueryValue('');
    onFilterChange({ title: '', vendors: [] });
  }, [onFilterChange]);

  const appliedFilters: IAppliedFilter[] = useMemo(() => {
    const appliedVendors = {
      key: 'selectedVendors',
      label: `Brands: ${selectedVendors.join(', ')}`,
      onRemove: handleSelectedVendorsRemove,
    };

    if (selectedVendors.length) return [appliedVendors];
    return [];
  }, [selectedVendors, handleSelectedVendorsRemove]);

  const submitMatches = (submittedPrices: IMatchPrices) => {
    if (allResourcesSelected) {
      linkAllMatches({ title: queryValue, vendors: selectedVendors });
      setSelectedVendors([]);
      setQueryValue('');
      deselectAll();
      return;
    }

    // Merge changed prices into selected products
    linkMatches(
      matches
        .filter((m) => selectedResources.some((id) => id === m._id))
        .map(({ retailerProduct: rp, supplierProduct: sp, ...rest }) => ({
          retailerProduct: {
            ...rp,
            variants: rp.variants.map((v) => ({
              ...v,
              price: submittedPrices[rp.shopifyId][v.sku].price,
              deleteOption: submittedPrices[rp.shopifyId][v.sku].option,
            })),
          },
          supplierProduct: {
            ...sp,
            variants: sp.variants.map((v) => ({
              ...v,
              price: submittedPrices[rp.shopifyId][v.sku].price,
            })),
          },
          ...rest,
        })),
    );

    deselectAll();
  };

  const getVariantInputName = useCallback(
    (sku: string, shopifyId: number) => `${shopifyId}.${sku}.price`,
    [],
  );
  const getVariantSelectName = useCallback(
    (sku: string, shopifyId: number) => `${shopifyId}.${sku}.option`,
    [],
  );

  const renderItem = useCallback(
    (item: IProductMatch, index: number) => {
      return (
        <SyncMatch
          storeHost={storeHost}
          position={index}
          selected={selectedResources.includes(item.id)}
          match={item}
          toggleExpansion={toggleExpansion}
          variantsExpanded={expandedVariants.includes(item.id)}
          variants={
            <MatchVariantList
              retailersVariants={item.retailerProduct.variants}
              suppliersVariants={item.supplierProduct.variants}
              getInputName={(sku) => getVariantInputName(sku, item.retailerProduct.shopifyId)}
              getSelectName={(sku) => getVariantSelectName(sku, item.retailerProduct.shopifyId)}
              disableInputs={
                (disableAdding && !selectedResources.includes(item.id)) || disableSubmit
              }
            />
          }
        />
      );
    },
    [
      storeHost,
      disableAdding,
      disableSubmit,
      expandedVariants,
      toggleExpansion,
      getVariantInputName,
      getVariantSelectName,
      selectedResources,
    ],
  );

  const selectionChanged = (
    selectionType: SelectionType,
    isSelecting: boolean,
    selection?: any,
  ) => {
    // when selecting a single item, ignore if limit is reached (disableAdding) or if the item is processing
    if (selectionType === 'single' && isSelecting && disableAdding) return;
    handleSelectionChange(selectionType, isSelecting, selection);
  };

  const deselectAll = () => handleSelectionChange('page' as SelectionType, false);

  return (
    <Formik initialValues={prices} onSubmit={submitMatches} enableReinitialize>
      {({ submitForm }) => (
        <Form name="matches">
          <div className="sync-matches">
            <AppCard>
              <AppFilters
                filters={[
                  {
                    key: 'selectedVendors',
                    label: 'Brands',
                    shortcut: true,
                    filter: (
                      <AppChoiceList
                        title="Brand list"
                        titleHidden
                        choices={vendorOptions}
                        selected={selectedVendors}
                        onChange={handleSelectedVendorsChange}
                        allowMultiple
                      />
                    ),
                  },
                ]}
                appliedFilters={appliedFilters}
                onQueryChange={handleFiltersQueryChange}
                onQueryClear={() => handleQueryValueRemove()}
                onClearAll={handleFiltersClearAll}
                queryValue={queryValue}
              />
              <AppIndexTable
                resourceName={{ singular: 'match', plural: 'matches' }}
                headings={[
                  { title: 'Image', hidden: true },
                  { title: 'Title' },
                  { title: 'Brand' },
                  { title: 'My Qty' },
                  { title: 'Supplier Qty' },
                  { title: 'Variants' },
                  { title: 'SKUs', hidden: true },
                ]}
                itemCount={matches.length}
                onSelectionChange={selectionChanged}
                selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
                promotedBulkActions={[
                  { content: 'Link products', onAction: submitForm, disabled: disableSubmit },
                ]}
                hasMoreItems={pageCount > 1}
                loading={loading}
              >
                {matches.map(renderItem)}
              </AppIndexTable>
              <PageAwarePagination totalPageCount={pageCount} onPageChange={handlePageChange} />
            </AppCard>
          </div>
        </Form>
      )}
    </Formik>
  );
}
