import { axiosInstance } from '../../core/api/base.api';

class AuthApi {
  private backend = process.env.REACT_APP_BACK_END_URL as string;
  private rootPath = '/api/retailer/standalone/auth';

  async getShopifyAuthRedirectUrl(storeHost: string) {
    return await axiosInstance.get<{ url: string } | { notFound: true }>(
      `${this.backend}${this.rootPath}/shopify`,
      { params: { storeHost } },
    );
  }

  async getShopifyAppInstallUrl(storeHost: string) {
    return await axiosInstance.get<{ url: string } | { accountExists: true }>(
      `${this.backend}${this.rootPath}/shopify/connect`,
      { params: { storeHost } },
    );
  }

  async confirmAuthViaShopify(query: string) {
    return await axiosInstance.get<{ token: string }>(
      `${this.backend}${this.rootPath}/shopify/callback/oauth${query}`,
    );
  }

  async finishAccountConnection(query: string) {
    return await axiosInstance.get<{ token: string }>(
      `${this.backend}${this.rootPath}/shopify/callback/install${query}`,
    );
  }

  async checkEmail(email: string) {
    return await axiosInstance.get<{ exists: boolean }>(`${this.backend}${this.rootPath}/email`, {
      params: { email },
    });
  }

  async register(body: { businessName: string; email: string; password: string }) {
    return await axiosInstance.post<{ token: string }>(
      `${this.backend}${this.rootPath}/register`,
      body,
    );
  }

  async login(body: { email: string; password: string }) {
    return await axiosInstance.post<{ token: string } | { error: true }>(
      `${this.backend}${this.rootPath}/login`,
      body,
    );
  }
}

export const authApi = new AuthApi();
