import React, { Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBadge } from '../../../../core/components/badge/badge';
import { AppButton } from '../../../../core/components/button/Button';
import { AppSkeletonBodyText } from '../../../../core/components/feedback-indicators/skeleton/skeleton-body-text/skeleton-body-text';
import { AppSkeletonPage } from '../../../../core/components/feedback-indicators/skeleton/skeleton-page/skeleton-page';
import { AppToast } from '../../../../core/components/feedback-indicators/toast/toast';
import { AppCard } from '../../../../core/components/structure/card/card';
import { AppEmptyState } from '../../../../core/components/structure/empty-state/empty-state';
import { AppPage } from '../../../../core/components/structure/page/page';
import { AppTabs } from '../../../../core/components/tabs/tabs';
import {
  ProductSyncTab,
  ProductSyncTabId,
  productSyncTabs,
  PRODUCTS_PER_PAGE,
} from '../../../../core/constants/product.constants';
import emptyStateImage from '../../../../core/images/Shopify/Empty States/404.svg';
import { getStoreHostSelector } from '../../../../core/redux/modules/auth/auth.selectors';
import {
  IProductMatch,
  IProductSync,
  IProductSyncMatchFilter,
} from '../../../interfaces/IProductsSync';
import { getStatusSelector } from '../../../redux/modules/status/status.selectors';
import {
  getSyncConflictsAction,
  getSyncMatchesAction,
  getSyncStatusAction,
  getVendorsForMatchesAction,
  hideLimitExceededBannerAction,
  hideLinkToastAction,
  linkAllMatchesAction,
  linkMatchesAction,
  resolveConflictAction,
  startProductSyncAction,
} from '../../../redux/modules/sync/sync.actions';
import {
  getSyncResultFetchingConflictsSelector,
  getSyncResultFetchingMatchesSelector,
  getSyncResultSelector,
  getVendorsForMatchesSelector,
  showSuccessLinkToastSelector,
  showSyncLimitExceededBannerSelector,
} from '../../../redux/modules/sync/sync.selectors';
import { ProductsLimitErrorBanner } from '../../banners/products-limit-error-banner/products-limit-error-banner';
import { SyncConflictList } from '../../containers/sync/sync-conflict-list/sync-conflict-list';
import { SyncMatchList } from '../../containers/sync/sync-match-list/sync-match-list';
import { ProductSyncConfirmationModal } from './product-sync-confirmation-modal';
import './product-sync-layout.scss';

export const ProductSyncLayout = () => {
  const pageTitle = 'Products synchronization';
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(0);
  const syncResult = useSelector(getSyncResultSelector);
  const vendors = useSelector(getVendorsForMatchesSelector);
  const storeHost = useSelector(getStoreHostSelector);
  const status = useSelector(getStatusSelector);
  const showLimitExceededBanner = useSelector(showSyncLimitExceededBannerSelector);
  const fetchingConflicts = useSelector(getSyncResultFetchingConflictsSelector);
  const fetchingMatches = useSelector(getSyncResultFetchingMatchesSelector);
  const linkToast = useSelector(showSuccessLinkToastSelector);

  const [limitReached, setLimitReached] = useState<boolean>(
    !!status && status.productsLimit !== undefined && status.productsLimit === status.productsCount,
  );
  const [limitExceeded, setLimitExceeded] = useState<boolean>(false);

  const limit: number | undefined = useMemo(
    () => status?.productsLimit && status.productsLimit - status.productsCount,
    [status],
  );

  const [pendingProductLinkMatches, setPendingProductLink] = useState<IProductMatch[] | undefined>(
    undefined,
  );
  const [pendingConflictResolution, setPendingConflictResolution] = useState<
    { conflictId: string; match: IProductMatch } | undefined
  >(undefined);

  const hideErrorBanner = useCallback(() => {
    dispatch(hideLimitExceededBannerAction());
  }, [dispatch]);
  const hideToast = useCallback(() => {
    dispatch(hideLinkToastAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getSyncStatusAction());
    dispatch(getSyncMatchesAction({ limit: PRODUCTS_PER_PAGE, page: 0 }));
    dispatch(getSyncConflictsAction());
    dispatch(getVendorsForMatchesAction());
    return () => {
      hideToast();
      hideErrorBanner();
    };
  }, [dispatch, hideToast, hideErrorBanner]);

  const matchesPagesCount = useMemo(
    () => Math.ceil(syncResult.totalMatches / PRODUCTS_PER_PAGE),
    [syncResult.totalMatches],
  );

  const handleMatchesPageChange = useCallback(
    (newPage: number, filter: IProductSyncMatchFilter) =>
      dispatch(getSyncMatchesAction({ limit: PRODUCTS_PER_PAGE, page: newPage, ...filter })),
    [dispatch],
  );

  const handleFilterChange = useCallback(
    (filter: IProductSyncMatchFilter) =>
      dispatch(getSyncMatchesAction({ limit: PRODUCTS_PER_PAGE, page: 0, ...filter })),
    [dispatch],
  );

  const handleTabChange = useCallback((selectedTabIndex) => setSelected(selectedTabIndex), []);

  const startSync = useCallback(() => {
    dispatch(startProductSyncAction());
    setSelected(0);
  }, [dispatch]);

  const linkMatches = useCallback(
    (values: IProductMatch[]) => {
      dispatch(linkMatchesAction(values));
      setPendingProductLink(undefined);
    },
    [dispatch],
  );

  const resolveConflict = useCallback(
    (conflict: { conflictId: string; match: IProductMatch }) => {
      dispatch(resolveConflictAction(conflict));
      setPendingConflictResolution(undefined);
    },
    [dispatch],
  );

  const handleConflictResolution = useCallback(
    (conflict: { conflictId: string; match: IProductMatch }) => {
      if (conflict.match.retailerProduct.variants.some((v) => v.isObsolete))
        setPendingConflictResolution(conflict);
      else resolveConflict(conflict);
    },
    [resolveConflict],
  );

  const handleMatchesLinking = useCallback(
    (matches: IProductMatch[]) => {
      if (matches.some((m) => m.retailerProduct.variants.some((v) => v.isObsolete)))
        setPendingProductLink(matches);
      else linkMatches(matches);
    },
    [linkMatches],
  );

  const handleAllMatchesLinking = useCallback(
    (filter: IProductSyncMatchFilter) => dispatch(linkAllMatchesAction(filter)),
    [dispatch],
  );

  const foundMatchesContent = useMemo(
    () => (
      <SyncMatchList
        storeHost={storeHost as string} // we do not render this block if storeHost is undefined
        vendors={vendors}
        linkMatches={handleMatchesLinking}
        linkAllMatches={handleAllMatchesLinking}
        matches={syncResult.matches}
        limit={limit}
        pageCount={matchesPagesCount}
        onPageChange={handleMatchesPageChange}
        onFilterChange={handleFilterChange}
        onLimitReachedStatusChange={setLimitReached}
        onLimitExceededStatusChange={setLimitExceeded}
        loading={fetchingMatches}
      />
    ),
    [
      syncResult.matches,
      storeHost,
      vendors,
      limit,
      setLimitExceeded,
      setLimitReached,
      matchesPagesCount,
      handleMatchesPageChange,
      fetchingMatches,
      handleMatchesLinking,
      handleAllMatchesLinking,
      handleFilterChange,
    ],
  );

  const unresolvedConflictsContent = useMemo(
    () => (
      <SyncConflictList
        storeHost={storeHost as string} // we do not render this block if storeHost is undefined
        conflicts={syncResult.conflicts}
        resolveConflict={handleConflictResolution}
        disableAdding={limit === 0}
      />
    ),
    [syncResult.conflicts, storeHost, limit, handleConflictResolution],
  );

  const appendBadgeToTabTitle = useCallback((tab: ProductSyncTab, qty: number) => {
    const badge = (
      <AppBadge size="small" status={qty ? 'attention' : 'new'}>
        {qty.toString()}
      </AppBadge>
    );
    return {
      ...tab,
      content: (
        <span>
          {tab.content} {badge}
        </span>
      ),
    };
  }, []);

  const tabs = useMemo(() => {
    const getTabCount = (
      tabId: ProductSyncTabId,
      { totalMatches, conflicts }: IProductSync,
    ): number => {
      if (tabId === 'found-matches') return totalMatches;
      if (tabId === 'unresolved-conflicts') return conflicts.length;
      throw new Error(`Unexpected tab id: ${tabId}`);
    };

    if (!syncResult || (!syncResult.conflicts.length && !syncResult.totalDisconnectedProducts))
      return [];
    return productSyncTabs.map((tab) =>
      appendBadgeToTabTitle(tab, getTabCount(tab.id, syncResult)),
    );
  }, [syncResult, appendBadgeToTabTitle]);

  const tabContent: { [key: number]: ReactNode } = useMemo(
    () => ({
      0: foundMatchesContent,
      1: unresolvedConflictsContent,
    }),
    [foundMatchesContent, unresolvedConflictsContent],
  );
  const pageWithTabsMarkup = useMemo(
    () => (
      <AppTabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
        {tabContent[selected]}
      </AppTabs>
    ),
    [handleTabChange, tabContent, selected, tabs],
  );

  const toastMarkup = useMemo(
    () =>
      linkToast.show ? (
        <AppToast
          error={!linkToast.success}
          onDismiss={hideToast}
          content={
            linkToast.failed
              ? `Could not connect ${linkToast.failed} products due to options mismatch`
              : 'Products connected'
          }
        />
      ) : null,
    [linkToast, hideToast],
  );

  const confirmationModalMarkup = useMemo(
    () => (
      <ProductSyncConfirmationModal
        open={!!(pendingProductLinkMatches || pendingConflictResolution)}
        onClose={() => {
          setPendingConflictResolution(undefined);
          setPendingProductLink(undefined);
        }}
        onConfirm={() => {
          if (pendingProductLinkMatches) linkMatches(pendingProductLinkMatches);
          if (pendingConflictResolution) resolveConflict(pendingConflictResolution);
        }}
        matches={
          pendingProductLinkMatches ||
          (pendingConflictResolution && [pendingConflictResolution.match]) ||
          []
        }
      />
    ),
    [pendingProductLinkMatches, pendingConflictResolution, linkMatches, resolveConflict],
  );

  const pageMarkup = useMemo(
    () => (
      <AppPage
        title={pageTitle}
        primaryAction={{
          content: 'Search for matches',
          onAction: startSync,
          disabled: syncResult.syncInProgress,
        }}
      >
        {syncResult.conflicts.length ? pageWithTabsMarkup : foundMatchesContent}
        {syncResult.conflicts.length && selected === 0 ? (
          <div style={{ paddingTop: '1rem' }}>
            <AppButton onClick={() => setSelected(1)} plain>
              View unresolved conflicts &gt;
            </AppButton>
          </div>
        ) : null}
        {toastMarkup}
        {(pendingProductLinkMatches || pendingConflictResolution) && confirmationModalMarkup}
      </AppPage>
    ),
    [
      startSync,
      syncResult.syncInProgress,
      syncResult.conflicts.length,
      pageWithTabsMarkup,
      foundMatchesContent,
      selected,
      toastMarkup,
      pendingProductLinkMatches,
      pendingConflictResolution,
      confirmationModalMarkup,
    ],
  );

  const skeletonPageMarkup = useMemo(
    () => (
      <AppSkeletonPage title={pageTitle} primaryAction>
        <AppCard sectioned>
          <AppSkeletonBodyText />
        </AppCard>
      </AppSkeletonPage>
    ),
    [],
  );

  const syncingInProgressPage = useMemo(
    () => (
      <AppEmptyState heading="Products synchronization is in progress" image={emptyStateImage}>
        <p>Please, visit this page later to see found matches</p>
      </AppEmptyState>
    ),
    [],
  );

  if (syncResult.syncInProgress) return syncingInProgressPage;

  return (
    <Fragment>
      {status &&
      status.productsLimit &&
      (showLimitExceededBanner || limitReached || limitExceeded) ? (
        <ProductsLimitErrorBanner
          limit={status.productsLimit}
          added={status.productsCount}
          onDismiss={hideErrorBanner}
          error={showLimitExceededBanner || limitExceeded}
        />
      ) : null}
      <div className="product-sync-layout">
        {(!storeHost || fetchingConflicts) && skeletonPageMarkup}
        {storeHost && !fetchingConflicts && pageMarkup}
      </div>
    </Fragment>
  );
};
