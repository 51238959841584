import { ExternalIcon } from '@shopify/polaris-icons';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppSkeletonBodyText } from '../../../../core/components/feedback-indicators/skeleton/skeleton-body-text/skeleton-body-text';
import { AppSkeletonPage } from '../../../../core/components/feedback-indicators/skeleton/skeleton-page/skeleton-page';
import { AppContextualSaveBar } from '../../../../core/components/forms/contextual-save-button/contextual-save-button';
import { AppCard } from '../../../../core/components/structure/card/card';
import { AppPage } from '../../../../core/components/structure/page/page';
import { getStoreHostSelector } from '../../../../core/redux/modules/auth/auth.selectors';
import { setBreadcrumbsAction } from '../../../../core/redux/modules/breadcrumbs/breadcrumbs.actions';
import { inventoryApi } from '../../../api/inventory.api';
import { SALE_PRICE_OPTION } from '../../../interfaces/IPreferences';
import { IProductDetails, IVariantDetailsFromApi } from '../../../interfaces/IProductDetails';
import { ProductDetails } from '../../containers/product-details/product-details';
import './product-details-layout.scss';

export const ProductDetailsLayout = () => {
  const dispatch = useDispatch();
  const { id: productId } = useParams<{ id: string }>();
  const [updating, setUpdating] = useState(false); // updating prices, prevent "save" clicks
  const [loading, setLoading] = useState(false); // loading product, show skeleton page
  const [product, setProduct] = useState<IProductDetails | null>(null);
  const storeHost = useSelector(getStoreHostSelector);

  const getSelectedPriceSyncPreferenceOption = (variant: IVariantDetailsFromApi) => {
    if (variant.manuallySetPrice) return 'disable sync';
    if (variant.useCompanyDefault) return 'use company';
    return variant.salePriceOption === SALE_PRICE_OPTION.MSRP ? 'msrp' : 'cost';
  };

  const getProducts = useCallback(() => {
    setLoading(true);
    inventoryApi
      .getProductDetails(productId)
      .then(({ data }) => {
        setProduct({
          ...data,
          variants: data.variants.map((v) => ({
            ...v,
            priceSyncOption: getSelectedPriceSyncPreferenceOption(v),
          })),
        });
        dispatch(setBreadcrumbsAction({ [`${productId}`]: data.title }));
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false);
      });
  }, [productId, dispatch]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  const handleSave = useCallback(
    (productToSave: IProductDetails, { resetForm }: FormikHelpers<IProductDetails>) => {
      const updatedVariantPrices = productToSave.variants
        .map((v) => ({
          id: v.id,
          updateManuallySetPrice: v.manuallySetPrice,
          price: +v.price,
          useCompanyDefault: v.useCompanyDefault,
          salePriceOption: v.salePriceOption,
          markup: v.defaultMarkup,
        }))
        .filter((v) =>
          product?.variants.find(
            (initialVariant) =>
              initialVariant.id === v.id &&
              (+initialVariant.price !== v.price ||
                initialVariant.manuallySetPrice !== v.updateManuallySetPrice ||
                initialVariant.useCompanyDefault !== v.useCompanyDefault ||
                initialVariant.salePriceOption !== v.salePriceOption ||
                initialVariant.defaultMarkup !== v.markup),
          ),
        );

      // could happen if the user has updated the fields from 100 to 100.00
      if (!updatedVariantPrices.length) {
        resetForm();
        return;
      }

      setUpdating(true);
      inventoryApi
        .updateProductPrices(productId, updatedVariantPrices)
        .catch(console.error)
        .finally(() => {
          getProducts();
          setUpdating(false);
        });
    },
    [productId, getProducts, product],
  );

  const skeletonPage = useMemo(
    () => (
      <AppSkeletonPage primaryAction>
        <AppCard sectioned>
          <AppSkeletonBodyText />
        </AppCard>
      </AppSkeletonPage>
    ),
    [],
  );

  return (
    <div className="product-details-layout">
      {loading || !product ? (
        skeletonPage
      ) : (
        <AppPage
          title={product.title}
          breadcrumbs={[{ content: 'My products', url: '/my-products' }]}
          secondaryActions={[
            {
              content: 'View on Shopify',
              icon: ExternalIcon,
              onAction: () => {
                window.open(`https://${storeHost}/admin/products/${product.shopifyId}`);
              },
            },
          ]}
        >
          <Formik initialValues={product} onSubmit={handleSave}>
            {({ submitForm, resetForm, dirty, values, setFieldValue }) => (
              <Form name="product-details">
                {dirty && (
                  <AppContextualSaveBar
                    message="Unsaved changes"
                    saveAction={{ onAction: submitForm, loading: updating }}
                    discardAction={{ onAction: resetForm, loading: updating }}
                  />
                )}
                <ProductDetails
                  product={product}
                  unsavedProduct={values}
                  setFieldValue={setFieldValue}
                  setUpdating={setUpdating}
                />
              </Form>
            )}
          </Formik>
        </AppPage>
      )}
    </div>
  );
};
