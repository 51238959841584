import React, { useCallback, useMemo, useState } from 'react';
import { PageDownIcon } from '@shopify/polaris-icons';
import { AppBanner } from '../../../../../core/components/feedback-indicators/banner/banner';
import { AppThumbnail } from '../../../../../core/components/image-containers/thumbnail/Thumbnail';
import { AppLink } from '../../../../../core/components/link/link';
import { ShippingAddress } from '../../../../../core/components/order-details/shipping-address/ShippingAddress';
import { AppTooltip } from '../../../../../core/components/overlays/tooltip/tooltip';
import { AppCard } from '../../../../../core/components/structure/card/card';
import { CardSubsection } from '../../../../../core/components/structure/card/card-subsection';
import { withCurrency } from '../../../../../core/helpers/currency.helper';
import { ICurrency } from '../../../../../core/interfaces/ICurrency';
import {
  IRetailerPurchaseOrderDetails,
  RETAILER_ORDER_PROCESSING_ERROR,
} from '../../../../interfaces/IOrder';
import { InvoiceDownloadLink } from '../common/invoice-download-link/invoice-download-link';
import { TrackingNumbers } from '../common/tracking-numbers/tracking-numbers';
import './purchase-order-details.scss';
import { AppCollapsible } from '../../../../../core/components/collapsible/Collapsible';
import { ArrowDownIcon } from '@shopify/polaris-icons';
import { AppIcon } from '../../../../../core/components/icon/icon';
import { invoiceApi } from '../../../../api/invoice.api';

type PurchaseOrderDetailsProps = {
  details: IRetailerPurchaseOrderDetails;
  currency?: ICurrency;
};

export const PurchaseOrderDetails = ({
  details,
  currency = { isoCode: 'USD', symbol: '$' },
}: PurchaseOrderDetailsProps) => {
  const activeProducts = useMemo(
    () =>
      details.lineItems.filter(
        (p) => p.cancellations.reduce((q, c) => q + c.quantity.accepted, 0) !== p.totalQty,
      ),
    [details.lineItems],
  );

  const cancelledProducts = useMemo(
    () => details.lineItems.filter((p) => p.cancellations.some((c) => c.quantity.accepted)),
    [details.lineItems],
  );

  const [openListShippingInvoices, setOpenListShippingInvoices] = useState(false);
  const [downloadingCreditPdf, setDownloadingCreditPdf] = useState(false);

  const handleClickShippingInvoices = useCallback(() => {
    setOpenListShippingInvoices(!openListShippingInvoices);
  }, [openListShippingInvoices]);

  const handleDownloadCreditPdf = useCallback(
    (creditId?: string) => {
      if (!creditId || downloadingCreditPdf) return;
      setDownloadingCreditPdf(true);
      invoiceApi
        .getCreditPdfUrl(creditId)
        .then(({ data }) => {
          window.open(data.url);
        })
        .catch(console.error)
        .finally(() => {
          setDownloadingCreditPdf(false);
        });
    },
    [downloadingCreditPdf],
  );

  const activeProductList = useMemo(
    () => (
      <div className="line-items-list">
        {!activeProducts.length && (
          <CardSubsection>No active products in this order</CardSubsection>
        )}
        {!!activeProducts.length && (
          <CardSubsection>
            <div className={'line-item-row header'}>
              <div className="image">Image</div>
              <div className="item">Title</div>
              <div>SKU</div>
              <div className="fulfillment">Tracking</div>
              <div className="quantity">Fulfilled/Total</div>
              <div className="price">Sale price</div>
              <div className="cost">Cost</div>
            </div>
          </CardSubsection>
        )}
        {activeProducts.map((p, i) => (
          <CardSubsection key={i}>
            <div className="line-item-row">
              <div className="image">
                <AppThumbnail alt={p.productGroupTitle} source={p.image} />
              </div>
              <div className="item">
                <div className="title">
                  {p.productGroupTitle}
                  {p.title && p?.title.toLowerCase() !== 'default title' && ` - ${p.title}`}
                </div>
                {p.supplierProductGroupTitle !== p.productGroupTitle && (
                  <div className="variant">
                    <AppTooltip content="Supplier's title" dismissOnMouseOut>
                      {p.supplierProductGroupTitle}
                      {p.supplierTitle &&
                        p?.supplierTitle.toLowerCase() !== 'default title' &&
                        `: ${p.supplierTitle}`}
                    </AppTooltip>
                  </div>
                )}
              </div>
              <div className="item">
                <div className={`title${p.sku ? '' : ' italic'}`}>
                  <AppTooltip dismissOnMouseOut content={p.sku}>
                    <div className="sku-with-overflow">{p.sku || 'empty SKU'}</div>
                  </AppTooltip>
                </div>
                {p.supplierSku && p.supplierSku !== p.sku && (
                  <div className="variant">
                    <AppTooltip content={p.supplierSku} dismissOnMouseOut>
                      <div className="sku-with-overflow">{p.supplierSku}</div>
                    </AppTooltip>
                  </div>
                )}
              </div>

              <div className="fulfillment">
                <TrackingNumbers
                  numbers={p.trackingNumbers}
                  urls={p.trackingUrls}
                  companies={p.trackingCompanies}
                  fulfilledQty={!!p.fulfilledQty}
                />
              </div>

              <div className="quantity">{`${p.fulfilledQty}/${p.totalQty}`}</div>
              <div className="price">{withCurrency(p.price, currency)}</div>
              <div className="cost">{withCurrency(p.cost, currency)}</div>
            </div>
          </CardSubsection>
        ))}
      </div>
    ),
    [activeProducts, currency],
  );

  const cancelledProductList = useMemo(
    () => (
      <div className="line-items-list no-tracking">
        <CardSubsection>
          <div className={'line-item-row header'}>
            <div className="image">Image</div>
            <div className="item">Title</div>
            <div>SKU</div>
            <div className="quantity">Cancelled/Total</div>
            <div className="price">Sale price</div>
            <div className="cost">Cost</div>
          </div>
        </CardSubsection>
        {cancelledProducts.map((p, i) => (
          <CardSubsection key={i}>
            <div className="line-item-row">
              <div className="image">
                <AppThumbnail alt={p.productGroupTitle} source={p.image} />
              </div>
              <div className="item">
                <div className="title">
                  {p.productGroupTitle}
                  {p.title && p?.title.toLowerCase() !== 'default title' && ` - ${p.title}`}
                </div>
                {p.supplierProductGroupTitle !== p.productGroupTitle && (
                  <div className="variant">
                    <AppTooltip content="Supplier's title" dismissOnMouseOut>
                      {p.supplierProductGroupTitle}
                      {p.supplierTitle &&
                        p?.supplierTitle.toLowerCase() !== 'default title' &&
                        `: ${p.supplierTitle}`}
                    </AppTooltip>
                  </div>
                )}
              </div>
              <div className="item">
                <div className={`title${p.sku ? '' : ' italic'}`}>
                  <AppTooltip content={p.sku} dismissOnMouseOut>
                    <div className="sku-with-overflow">{p.sku || 'empty SKU'}</div>
                  </AppTooltip>
                </div>
                {p.supplierSku && p.supplierSku !== p.sku && (
                  <div className="variant">
                    <AppTooltip content={p.supplierSku} dismissOnMouseOut>
                      <div className="sku-with-overflow">{p.supplierSku}</div>
                    </AppTooltip>
                  </div>
                )}
              </div>
              <div className="quantity">
                {`${p.cancellations.reduce((q, c) => q + c.quantity.accepted, 0)}/${p.totalQty}`}
              </div>
              <div className="price">{withCurrency(p.price, currency)}</div>
              <div className="cost">{withCurrency(p.cost, currency)}</div>
            </div>
          </CardSubsection>
        ))}
      </div>
    ),
    [cancelledProducts, currency],
  );

  const totals = useMemo(
    () => (
      <>
        <CardSubsection>
          <div className="totals-desc-list">
            <div className="totals-desc-row">
              <div className="title"></div>
              <div className="desc"></div>
              <div className="price">Consumer paid</div>
              <div className="cost">PO Total</div>
            </div>
          </div>
        </CardSubsection>
        <CardSubsection>
          <div className="totals-desc-list">
            <div className="totals-desc-row">
              <div className="title">Subtotal</div>
              <div className="desc">{details.lineItems.length} items</div>
              <div className="price">{withCurrency(details.salesTotals.subtotal, currency)}</div>
              <div className="cost">{withCurrency(details.purchaseTotals.subtotal, currency)}</div>
            </div>
            <div className="totals-desc-row">
              <div className="title">Shipping</div>
              <div className="shipping">{details.selectedShipping}</div>
              <div className="price">{withCurrency(details.salesTotals.shipping, currency)}</div>
              <div className="cost">{withCurrency(details.purchaseTotals.shipping, currency)}</div>
            </div>
            <div className="totals-desc-row">
              <div className="title">Handling fee</div>
              <div className="spacer"></div>
              <div className="spacer"></div>
              <div className="cost">
                {withCurrency(details.purchaseTotals.handlingFee, currency)}
              </div>
            </div>
            <div className="totals-desc-row bold">
              <div className="title">Total</div>
              <div className="spacer"></div>
              <div className="price">{withCurrency(details.salesTotals.total, currency)}</div>
              <div className="cost">{withCurrency(details.purchaseTotals.total, currency)}</div>
            </div>
            {!!details.salesTotals.refunded || !!details.purchaseTotals.refunded ? (
              <div className="totals-desc-row bold">
                <div className="title">Refunded</div>
                <div className="spacer"></div>
                <div className="price">
                  {withCurrency(details.salesTotals.refunded || 0, currency)}
                </div>
                <div className="cost">
                  {withCurrency(details.purchaseTotals.refunded || 0, currency)}
                </div>
              </div>
            ) : null}
            {!!details.refunds && !!details.refunds.length
              ? details.refunds.map((r, i) => (
                  <div className="totals-desc-row bold">
                    <div className="title">Refund {i > 0 ? i : ''}</div>
                    <div className="desc">Manual: {r.reason}</div>
                    <div className="price">{withCurrency(0, currency)}</div>
                    <div className="cost">-{withCurrency(r.amount, currency)}</div>
                  </div>
                ))
              : null}
          </div>
        </CardSubsection>
        <CardSubsection>
          <div className="totals-desc-list bold">
            <div className="totals-desc-row">
              <div className="spacer"></div>
              <div className="spacer"></div>
              <div className="title">Amount Paid:</div>
              <div className="cost">{withCurrency(details.amountPaid, currency)}</div>
            </div>
            <div className="totals-desc-row">
              <div className="spacer"></div>
              <div className="spacer"></div>
              <div className="title">Amount Due:</div>
              <div className="cost">{withCurrency(details.amountDue, currency)}</div>
            </div>
            <div className="totals-desc-row">
              <div className="spacer"></div>
              <div className="spacer"></div>
              <div className="title">Profit:</div>
              <div className="cost">{withCurrency(details.profit, currency)}</div>
            </div>
            <div className="totals-desc-row">
              <div className="spacer"></div>
              <div className="spacer"></div>
              <div className="title">Margin:</div>
              <div className="cost">{details.margin.toFixed(2)}%</div>
            </div>
            <div className="totals-desc-row not-bold">
              <div className="desc">Crowdship Fee</div>
              <div className="desc">
                {details.feePercent}% (set in your{' '}
                <AppLink url="/settings?tab=subscription-plan">plan subscription</AppLink>)
              </div>
              <div className="spacer"></div>
              <div className="cost desc">{withCurrency(details.purchaseTotals.fee, currency)}</div>
            </div>
          </div>
        </CardSubsection>
      </>
    ),
    [details, currency],
  );

  const errorText = useMemo(() => {
    switch (details.error?.type) {
      case RETAILER_ORDER_PROCESSING_ERROR.INVALID_ADDRESS:
      case RETAILER_ORDER_PROCESSING_ERROR.NO_SHIPPING_ADDRESS:
        return `An issue has occurred due to invalid shipping address. Please edit order
        shipping address.`;
      case RETAILER_ORDER_PROCESSING_ERROR.PENDING_REPROCESSING:
        return `This order could not be processed due to an invalid shipping address. 
        Please provide a valid shipping address and retry processing the order.`;
      default:
        return (
          <span>
            An unexpected issue has occurred during order processing. Please contact us at{' '}
            {
              <a
                href="mailto:support@crowdship.io"
                target="_blank"
                rel="noreferrer"
                className="mailLink"
              >
                support@crowdship.io
              </a>
            }
          </span>
        );
    }
  }, [details]);

  return (
    <div className="main-layout">
      <div className="layout-purchase-order-details">
        <div className="purchase-order-details">
          {details.status === 'payment failed' && (
            <AppBanner status="critical">
              We had issues charging you for these products thus, they will not be shown to the
              suppliers.
            </AppBanner>
          )}
          {details.status === 'error' && <AppBanner status="critical">{errorText}</AppBanner>}

          <AppCard
            title={`Status: ${details.status.replace('error', 'needs attention')}`}
            sections={[{ content: activeProductList }]}
          />
          {!!cancelledProducts.length && (
            <AppCard title="Cancelled products" sections={[{ content: cancelledProductList }]} />
          )}
          <AppCard title="Totals" sections={[{ content: totals }]} />
        </div>
      </div>

      <div className="main-info">
        <ShippingAddress
          shippingAddress={details.shippingAddress}
          originalShippingAddress={details.originalShippingAddress}
        />
        <div className="shipping">
          <AppCard sectioned>
            <InvoiceDownloadLink invoiceId={details.invoiceId} link={details.invoiceDownloadLink} />
            <InvoiceDownloadLink
              title="Transaction fee invoice: "
              invoiceId={details.transactionFeeInvoiceId}
              link={details.feeInvoiceDownloadLink}
            />
            <div>Supplier: {details.supplierName}</div>
            <div>Supplier Order Name: {details.supplierOrderName || 'not created'}</div>
            <div>Supplier email: {details.supplierEmail || 'not provided'}</div>
            <div>
              Supplier phone: {details.supplierPhone ? `${details.supplierPhone}` : 'not provided'}
            </div>
            {!!details.shippingInvoices && (
              <>
                <div className="invoices" onClick={handleClickShippingInvoices}>
                  Shipping invoices
                  <ArrowDownIcon
                    className={'arrow ' + (openListShippingInvoices ? 'reverted-arrow' : '')}
                  />
                </div>
                <AppCollapsible open={openListShippingInvoices} id="shipping-invoices">
                  <div className="invoices-wrapper">
                    {details.shippingInvoices.map((si) => (
                      <div className="invoice-wrapper">
                        <p className="provider">{`${si.providerName}: `}</p>
                        {si.refunded ? (
                          <div className="refunded-item">
                            <p>{si.shippingInvoiceId}</p>
                            {!!si.creditNoteId && (
                              <AppTooltip content="Download credit notes PDF" dismissOnMouseOut>
                                <AppLink onClick={() => handleDownloadCreditPdf(si.creditNoteId)}>
                                  <AppIcon color="highlight" source={PageDownIcon} />
                                </AppLink>
                              </AppTooltip>
                            )}
                          </div>
                        ) : (
                          <InvoiceDownloadLink
                            title=""
                            invoiceId={si.shippingInvoiceId}
                            link={si.downloadUrl}
                            key={si.shippingInvoiceId}
                          />
                        )}

                        {'\u2212'}
                        <p className={'invoice-price ' + (si.refunded ? 'refunded-price' : '')}>
                          {withCurrency(si.labelCost)}
                        </p>
                      </div>
                    ))}
                  </div>
                </AppCollapsible>
              </>
            )}
          </AppCard>
        </div>
      </div>
    </div>
  );
};
