import React from 'react';
import { useSelector } from 'react-redux';
import { AppButton } from '../../../../../core/components/button/Button';
import { AppThumbnail } from '../../../../../core/components/image-containers/thumbnail/Thumbnail';
import { AppIndexTable } from '../../../../../core/components/index-table/index-table';
import { AppIndexTableCell } from '../../../../../core/components/index-table/index-table-cell';
import { AppIndexTableRow } from '../../../../../core/components/index-table/index-table-row';
import { AppLink } from '../../../../../core/components/link/link';
import { AppTextStyle } from '../../../../../core/components/text/text-style/TextStyle';
import { getStoreHostSelector } from '../../../../../core/redux/modules/auth/auth.selectors';
import { IConnectedProducts } from '../../../../interfaces/IProduct';
import '../../order/order-cancellation/order-cancellation.scss';
import { PRODUCT_GROUP_CONNECTION_TYPE } from '../../../../../core/interfaces/IProduct';
import { AppTooltip } from '../../../../../core/components/overlays/tooltip/tooltip';

type ConnectedProductsProps = {
  connectedProducts: IConnectedProducts[];
  fetching: boolean;
  onDisconnect: (productId: string) => void;
  showLinks?: boolean;
  isVariantsSyncEnable: boolean;
};

export function ConnectedProducts({
  connectedProducts,
  fetching,
  onDisconnect,
  showLinks,
  isVariantsSyncEnable,
}: ConnectedProductsProps) {
  const storeHost = useSelector(getStoreHostSelector);
  return (
    <>
      <p>
        <AppTextStyle variation="warning">
          Warning: Disconnecting this product will reset fulfillment service in Shopify, and set
          inventory qty to 0. It will no longer be fulfilled by Crowdship.
        </AppTextStyle>
      </p>
      <AppIndexTable
        resourceName={{ singular: 'variant', plural: 'variants' }}
        headings={[
          { title: 'Image', hidden: true },
          { title: 'Title' },
          { title: 'Brand' },
          { title: 'SKU' },
          { title: 'Disconnect', hidden: true },
        ]}
        itemCount={connectedProducts.length}
        loading={fetching}
        selectable={false}
      >
        {connectedProducts.map((p, i) => (
          <AppIndexTableRow id={`${i}`} key={i} selected={false} position={i}>
            <AppIndexTableCell>
              <div className="product-image">
                <AppThumbnail alt={p.title} source={p.image} />
              </div>
            </AppIndexTableCell>
            <AppIndexTableCell>
              <div className="product-title">{p.title}</div>
              {p.retailerProductId && showLinks ? (
                <div>
                  <span style={{ fontSize: 'small' }}>
                    <AppLink url={`/my-products/${p.retailerProductGroup}`} monochrome>
                      View Crowdship product
                    </AppLink>
                    &nbsp;|&nbsp;
                    <a
                      href={`https://${storeHost}/admin/products/${p.retailerProductId}`}
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: 'inherit' }}
                    >
                      View Shopify Listing
                    </a>
                  </span>
                </div>
              ) : null}
            </AppIndexTableCell>
            <AppIndexTableCell>{p.vendor}</AppIndexTableCell>
            <AppIndexTableCell>{p.sku}</AppIndexTableCell>
            <AppIndexTableCell>
              {isVariantsSyncEnable &&
              p.connectionType === PRODUCT_GROUP_CONNECTION_TYPE.ADDED_GROUP ? (
                <AppTooltip
                  content={
                    <p>
                      To enable disconnecting individual variants, disable variant sync in{' '}
                      <AppLink url="/settings?tab=products">product sync settings</AppLink>
                    </p>
                  }
                >
                  <AppButton disabled>Disconnect</AppButton>
                </AppTooltip>
              ) : (
                <AppButton
                  handleClick={(e) => {
                    e.stopPropagation();
                    onDisconnect(p.id);
                  }}
                >
                  Disconnect
                </AppButton>
              )}
            </AppIndexTableCell>
          </AppIndexTableRow>
        ))}
      </AppIndexTable>
    </>
  );
}
