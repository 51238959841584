import axios from 'axios';
import jwt from 'jsonwebtoken';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { setAxiosAuthorization } from '../../../../core/api/base.api';
import { inFrame } from '../../../../core/helpers/frame.helper';
import { LoadingLayout } from '../../../../core/layouts/loading-layout/loading-layout';
import {
  setAuthenticatedAction,
  setHostAction,
  setRolesAction,
  setStoreHostAction,
} from '../../../../core/redux/modules/auth/auth.actions';

type IAuthenticationResponse =
  | { accessToken: string }
  | { archived: string }
  | { installationUrl: Location };

export function CallbackLayout() {
  const dispatch = useDispatch();
  const query = useLocation().search;
  const history = useHistory();

  useEffect(() => {
    const checkAuthentication = async () => {
      const BACK_END_URL = process.env.REACT_APP_BACK_END_URL;
      const data: IAuthenticationResponse = (
        await axios.get(`${BACK_END_URL}/api/supplier/shopify${query}`)
      ).data;
      if ('accessToken' in data && data.accessToken) {
        const parsedQuery = new URLSearchParams(query);
        const host = parsedQuery.get('host') as string;
        const storeHost = parsedQuery.get('shop') as string;
        const tokenData: any = jwt.decode(data.accessToken);
        if (!tokenData) throw new Error('Cannot parse token');
        setAxiosAuthorization(data.accessToken);
        dispatch(setAuthenticatedAction(data.accessToken));
        dispatch(setHostAction(host));
        dispatch(setStoreHostAction(storeHost));
        dispatch(setRolesAction(tokenData.roles));
        history.push('/account');
      }
    };

    const oauthCallback = async () => {
      const BACK_END_URL = process.env.REACT_APP_BACK_END_URL;
      const res = await axios.get<ICallbackResponse>(
        `${BACK_END_URL}/api/supplier/shopify/oauth/callback${query}`,
      );
      const { urlToRedirect } = res.data;
      const storeHost = new URLSearchParams(query).get('shop');
      if (!storeHost) throw new Error('No shop found in query');

      try {
        if (inFrame()) {
          checkAuthentication();
          return;
        }
        // @ts-ignore
        window.location = urlToRedirect;
      } catch (error) {
        console.error('error');
        history.push('/account/onboarding');
      }
    };
    if (query) {
      oauthCallback();
    }
  }, [query, history, dispatch]);

  return <LoadingLayout />;
}

interface ICallbackResponse {
  urlToRedirect: string;
}
