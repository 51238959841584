import { EmptySearchResult } from '@shopify/polaris';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Country, countryApi } from '../../../../core/api/country.api';
import { AppBanner } from '../../../../core/components/feedback-indicators/banner/banner';
import { AppSkeletonBodyText } from '../../../../core/components/feedback-indicators/skeleton/skeleton-body-text/skeleton-body-text';
import { AppSkeletonPage } from '../../../../core/components/feedback-indicators/skeleton/skeleton-page/skeleton-page';
import { AppToast } from '../../../../core/components/feedback-indicators/toast/toast';
import { DateTooltipUTC } from '../../../../core/components/overlays/date-tooltip-utc/date-tooltip-utc';
import { AppModal } from '../../../../core/components/overlays/modal/modal';
import { AppCard } from '../../../../core/components/structure/card/card';
import { AppPage } from '../../../../core/components/structure/page/page';
import { AppTextStyle } from '../../../../core/components/text/text-style/TextStyle';
import {
  getFormattedDate,
  getFullFormattedDate,
  getUTCDate,
} from '../../../../core/helpers/date.helper';
import { getStoreHostSelector } from '../../../../core/redux/modules/auth/auth.selectors';
import { IAddress } from '../../../../shopify-supplier/interfaces/IOrder';
import { ICancellationRequestLineItem } from '../../../api/orders.api';
import { RETAILER_ORDER_PROCESSING_ERROR } from '../../../interfaces/IOrder';
import {
  acceptCrowdshipCancellationAction,
  cancelOrderAction,
  chargeForOrderAction,
  editShippingAddressAction,
  getOrderDetailsAction,
  hideAddressValidationFailedToastAction,
  hideAddressValidationSuccessToastAction,
  hidePaymentFailedToastAction,
  hideUpdatingOrderStatusToastAction,
  processOrderAction,
  requestCancellationAction,
  revokeCancellationAction,
} from '../../../redux/modules/orders/orders.actions';
import {
  addressValidationToastsSelector,
  getOrderDetailsSelector,
  getRetailerOrderDetailsFetchingSelector,
  processingOrderSelector,
  requestingCancellationSelector,
  showPaymentFailedToastSelector,
  showUpdatingOrderStatusToastSelector,
  validatingAddressSelector,
} from '../../../redux/modules/orders/orders.selectors';
import { RootState } from '../../../redux/reducers';
import { OrderStatus } from '../../containers/order/common/order-status/order-status';
import { OrderCancellation } from '../../containers/order/order-cancellation/order-cancellation';
import { OrderDetails } from '../../containers/order/order-details/order-details';
import { OrderItemsStockUpdates } from '../../containers/order/order-items-stock-updates/order-items-stock-updates';
import { ShippingAddressModal } from '../../modals/shipping-address-modal/shipping-address-modal';
import './order-details-layout.scss';

interface OrderDetailsLayoutProp {
  orderId?: string;
}

export function OrderDetailsLayout(props: OrderDetailsLayoutProp) {
  const { id: orderId } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const details = useSelector((state: RootState) =>
    getOrderDetailsSelector(state as any, props.orderId || orderId),
  );

  const processingCancellation = useSelector(requestingCancellationSelector);
  const processingOrder = useSelector(processingOrderSelector);
  const fetching = useSelector(getRetailerOrderDetailsFetchingSelector);

  const paymentFailed = useSelector(showPaymentFailedToastSelector);
  const validating = useSelector(validatingAddressSelector);
  const { failedValidation, successValidation } = useSelector(addressValidationToastsSelector);
  const updatingOrderStatus = useSelector(showUpdatingOrderStatusToastSelector);
  const storeHost = useSelector(getStoreHostSelector);
  const [openCancellationModal, setOpenCancellationModal] = useState(false);
  const [openShippingAddressModal, setOpenShippingAddressModal] = useState(false);
  const [countries, setCountries] = useState<Country[]>([]);

  const hasPendingCrowdshipCancellation = useMemo(
    () => details?.cancellations.some((c) => c.pending && c.initiatedBy === 'crowdship'),
    [details],
  );

  const crowdshipCancellationUpdatedAt = useMemo(() => {
    if (!hasPendingCrowdshipCancellation) return false;
    const cancellation = details?.cancellations.find(
      (c) => c.pending && c.initiatedBy === 'crowdship',
    );
    if (!cancellation) return false;
    return getFormattedDate(cancellation.updatedAt);
  }, [hasPendingCrowdshipCancellation, details]);

  const [openCrowdshipCancellationModal, setOpenCrowdshipCancellationModal] = useState(
    hasPendingCrowdshipCancellation,
  );

  useEffect(() => {
    dispatch(getOrderDetailsAction(props.orderId || orderId));
  }, [dispatch, props.orderId, orderId]);

  useEffect(() => {
    countryApi.getCountries().then(({ data: result }) => {
      setCountries(result.data);
    });
  }, []);

  useEffect(() => {
    if (successValidation) setOpenShippingAddressModal(false);
  }, [successValidation]);

  const isCancelOrderOptionActive = useMemo(
    () =>
      details &&
      (['processing', 'error', 'account suspended'].includes(details.status) ||
        (details.status === 'payment failed' &&
          details.lineItems.every((p) => p.paidViaCrowdship))),
    [details],
  );
  const cancelButtonLbl = useMemo(() => {
    if (details && isCancelOrderOptionActive) return 'Cancel order';
    if (details?.cancellations.some((p) => p.pending)) {
      return 'Revoke cancellation request';
    }
    return 'Request cancellation';
  }, [details, isCancelOrderOptionActive]);

  const cancelButtonDisabled = useMemo(
    () =>
      !details ||
      ['processing', 'fulfilled', 'partially fulfilled', 'cancelled'].includes(details.status) ||
      details?.cancellations.some((c) => c.pending && c.initiatedBy === 'crowdship'),
    [details],
  );

  const editButtonDisabled = useMemo(
    () =>
      !details ||
      !(
        details.status === 'error' &&
        details.error &&
        [
          RETAILER_ORDER_PROCESSING_ERROR.NO_SHIPPING_ADDRESS,
          RETAILER_ORDER_PROCESSING_ERROR.INVALID_ADDRESS,
          RETAILER_ORDER_PROCESSING_ERROR.PENDING_REPROCESSING,
          RETAILER_ORDER_PROCESSING_ERROR.CANNOT_CALCULATE_SHIPPING,
        ].includes(details.error.type)
      ),
    [details],
  );

  // available only if order is not paid and not created for suppliers
  const cancelOrder = useCallback(() => {
    setOpenCrowdshipCancellationModal(false);
    dispatch(cancelOrderAction(props.orderId || orderId));
  }, [dispatch, props.orderId, orderId]);

  const acceptCrowdshipCancellation = useCallback(() => {
    setOpenCrowdshipCancellationModal(false);
    dispatch(acceptCrowdshipCancellationAction(props.orderId || orderId));
  }, [dispatch, props.orderId, orderId]);

  const requestCancellation = useCallback(
    (body: ICancellationRequestLineItem[]) => {
      dispatch(
        requestCancellationAction(
          props.orderId || orderId,
          body.filter((b) => b.quantity !== '0'),
        ),
      );
    },
    [dispatch, props.orderId, orderId],
  );

  const revokeCancellation = useCallback(() => {
    dispatch(revokeCancellationAction(props.orderId || orderId));
  }, [dispatch, props.orderId, orderId]);

  const editShippingAddress = useCallback(
    (address: IAddress, validate: boolean) => {
      dispatch(
        editShippingAddressAction(
          address,
          props.orderId || orderId,
          validate,
          !!details?.originalShippingAddress,
        ),
      );
    },
    [dispatch, props.orderId, orderId, details?.originalShippingAddress],
  );

  const onCancelButtonClick = useCallback(() => {
    if (details && isCancelOrderOptionActive) return cancelOrder();

    if (details && details.cancellations.some((c) => c.pending)) return revokeCancellation();

    return setOpenCancellationModal(true);
  }, [details, revokeCancellation, cancelOrder, isCancelOrderOptionActive]);

  const createCancellationModalMarkup = useMemo(() => {
    if (!details && !openCancellationModal) return null;
    return (
      <AppModal
        open={openCancellationModal}
        title={'Select products for cancellation'}
        onClose={() => {
          setOpenCancellationModal(false);
        }}
        large
      >
        <OrderCancellation
          products={details.lineItems.filter(
            (p) =>
              p.fulfilledQty + p.cancellations.reduce((q, c) => q + c.quantity.accepted, 0) !==
              p.totalQty,
          )}
          onSubmit={(products) => {
            requestCancellation(products);
            setOpenCancellationModal(false);
          }}
        />
      </AppModal>
    );
  }, [details, openCancellationModal, requestCancellation]);

  const hideUpdatingOrderStatusToast = useCallback(
    () => dispatch(hideUpdatingOrderStatusToastAction()),
    [dispatch],
  );
  const hidePaymentFailedToast = useCallback(
    () => dispatch(hidePaymentFailedToastAction()),
    [dispatch],
  );

  const hideAddressValidationFailedToast = useCallback(
    () => dispatch(hideAddressValidationFailedToastAction()),
    [dispatch],
  );

  const hideAddressValidationSuccessToast = useCallback(
    () => dispatch(hideAddressValidationSuccessToastAction()),
    [dispatch],
  );

  useEffect(() => {
    return () => {
      if (paymentFailed) hidePaymentFailedToast();
    };
  }, [paymentFailed, hidePaymentFailedToast]);

  const crowdshipCancellationModalMarkup = useMemo(() => {
    if (!details || !hasPendingCrowdshipCancellation) return null;
    return (
      <AppModal
        open={openCrowdshipCancellationModal}
        onClose={() => setOpenCrowdshipCancellationModal(false)}
        title="Order updates"
        large
      >
        <OrderItemsStockUpdates
          products={details.lineItems}
          onAccept={acceptCrowdshipCancellation}
          onCancel={cancelOrder}
        />
      </AppModal>
    );
  }, [
    details,
    hasPendingCrowdshipCancellation,
    openCrowdshipCancellationModal,
    acceptCrowdshipCancellation,
    cancelOrder,
  ]);

  const paymentFailedToastMarkup = useMemo(
    () =>
      paymentFailed && (
        <AppToast onDismiss={hidePaymentFailedToast} content="Payment failed" error />
      ),
    [hidePaymentFailedToast, paymentFailed],
  );

  const addressValidationFailedToastMarkup = useMemo(
    () =>
      failedValidation && (
        <AppToast
          onDismiss={hideAddressValidationFailedToast}
          content="Address failed validation - please try another address."
          error
        />
      ),
    [hideAddressValidationFailedToast, failedValidation],
  );

  const addressValidationSuccessToastMarkup = useMemo(
    () =>
      successValidation && (
        <AppToast onDismiss={hideAddressValidationSuccessToast} content="Validation passed" />
      ),
    [hideAddressValidationSuccessToast, successValidation],
  );

  const updatingOrderStatusToastMarkup = useMemo(
    () =>
      updatingOrderStatus && (
        <AppToast onDismiss={hideUpdatingOrderStatusToast} content="Updating status..." />
      ),
    [hideUpdatingOrderStatusToast, updatingOrderStatus],
  );

  const primaryActionButton = useMemo(() => {
    if (
      details &&
      details.status === 'payment failed' &&
      !details.purchaseOrders.every((po) => !po.paidViaCrowdship || po.status === 'cancelled')
    ) {
      return {
        content: 'Pay now',
        onAction: () => {
          dispatch(chargeForOrderAction(details.id));
        },
      };
    }

    if (
      details &&
      details.status === 'error' &&
      details.error &&
      details.error.type === RETAILER_ORDER_PROCESSING_ERROR.PENDING_REPROCESSING
    ) {
      return {
        content: 'Retry processing',
        onAction: () => {
          dispatch(processOrderAction(details.id));
        },
      };
    }

    return null;
  }, [dispatch, details]);

  if ((!details && fetching) || processingCancellation || processingOrder)
    return (
      <AppSkeletonPage>
        <AppCard sectioned>
          <AppSkeletonBodyText />
        </AppCard>
        <AppCard sectioned>
          <AppSkeletonBodyText />
        </AppCard>
      </AppSkeletonPage>
    );

  if (!fetching && !details)
    return (
      <div className="empty-order-page">
        <EmptySearchResult title="This order does not exist." withIllustration />
      </div>
    );

  return (
    <div className="order-details-layout">
      <AppPage
        title={`Order ${details.name}`}
        titleBarTitle={details.name}
        breadcrumbs={[{ content: 'Orders', url: '/orders?tab=orders' }]}
        titleMetadata={<OrderStatus status={details.status} />}
        secondaryActions={[
          ...(details.shopifyOrderId
            ? [
                {
                  content: 'View on Shopify',
                  onAction: () => {
                    window.open(`https://${storeHost}/admin/orders/${details.shopifyOrderId}`);
                  },
                },
              ]
            : []),
          {
            content: 'Edit address',
            disabled: details.status === 'cancelled',
            onAction: () => {
              setOpenShippingAddressModal(true);
            },
          },

          {
            content: cancelButtonLbl,
            disabled: cancelButtonDisabled,
            onAction: onCancelButtonClick,
          },
        ]}
        primaryAction={primaryActionButton}
      >
        <DateTooltipUTC
          date={details.createdAt}
          className="order-date"
          dateFormatter={getFullFormattedDate}
          tooltipFormatter={getUTCDate}
        />
        {hasPendingCrowdshipCancellation && (
          <div className="in-page-banner">
            <AppBanner
              status="critical"
              title="Some products are out of stock"
              action={{
                content: 'View details',
                onAction: () => setOpenCrowdshipCancellationModal(true),
              }}
            >
              <p>
                Unfortunately, some of the products from your order are now out of stock. View
                details and decide if you want to process the order and cancel the missing line
                items or cancel the whole order. You will not be charged for the cancelled line
                items.
              </p>
              <p>
                <AppTextStyle variation="subdued">
                  If your customer wishes to receive a substitute, please, create a new order
                </AppTextStyle>
              </p>
              {crowdshipCancellationUpdatedAt && (
                <p>
                  <AppTextStyle variation="subdued">
                    Last update: {crowdshipCancellationUpdatedAt}
                  </AppTextStyle>
                </p>
              )}
            </AppBanner>
          </div>
        )}
        <OrderDetails
          shippingAddress={details.shippingAddress}
          originalShippingAddress={details?.originalShippingAddress}
          status={details.status}
          purchaseOrders={details.purchaseOrders}
          lineItems={details.lineItems}
          totals={details.totals}
          crowdshipItemsCount={details.crowdshipItemsCount}
          error={details.error}
          editButtonDisabled={editButtonDisabled}
          onCancellationRequestEdit={() => {
            setOpenCancellationModal(true);
          }}
        />
      </AppPage>
      <ShippingAddressModal
        open={openShippingAddressModal}
        address={details.shippingAddress}
        validating={validating}
        countries={countries}
        onConfirm={editShippingAddress}
        onClose={() => setOpenShippingAddressModal(false)}
        isAddressValid={editButtonDisabled}
      />
      {addressValidationFailedToastMarkup}
      {addressValidationSuccessToastMarkup}
      {paymentFailedToastMarkup}
      {updatingOrderStatusToastMarkup}
      {createCancellationModalMarkup}
      {crowdshipCancellationModalMarkup}
    </div>
  );
}
