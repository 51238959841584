import React from 'react';
import { IProductMatch, IProductConflict } from '../../../../interfaces/IProductsSync';
import { SyncConflict } from './sync-conflict/sync-conflict';
import './sync-conflict-list.scss';

type SyncConflictListProps = {
  storeHost: string;
  conflicts: IProductConflict[];
  resolveConflict: (conflict: { conflictId: string; match: IProductMatch }) => void;
  disableAdding: boolean;
};

export const SyncConflictList = ({
  storeHost,
  conflicts,
  resolveConflict,
  disableAdding,
}: SyncConflictListProps) => (
  <div className="conflict-list">
    {!conflicts.length && <p className="no-conflicts">No conflicts found</p>}
    {conflicts.map((conf, i) => (
      <SyncConflict
        key={i}
        storeHost={storeHost}
        resolveConflict={resolveConflict}
        conflict={conf}
        disableAdding={disableAdding}
      />
    ))}
  </div>
);
