import { createReducer } from 'typesafe-actions';
import jwt from 'jsonwebtoken';
import { ACCOUNT_ROLE } from '../../../../core/redux/modules/auth/auth.reducer';
import { Integration, RETAILER_PLATFORM } from '../../../interfaces/integration.interface';
import { Actions } from '../../actions';
import { logoutAction, setAuthenticatedAction } from './auth.actions';

interface IAuthReducer {
  authenticated: boolean;
  name?: string;
  roles?: ACCOUNT_ROLE[];

  /**
   * will be removed when we'll aggregate data for all connected retailers
   * for now this is needed to reuse the shopify-retailer endpoints
   *  */
  retailerId?: string;
  retailerName?: string;
  storeHost?: string;
  integrations?: Integration[];
}

const initialState: IAuthReducer = {
  authenticated: false,
};

export const authReducer = createReducer<IAuthReducer, Actions>(initialState)
  .handleAction(setAuthenticatedAction, (_, { payload }) => {
    const tokenData = jwt.decode(payload.token) as IRetailerTokenData;
    const integration = tokenData.integrations.find(
      (i) => i.platform === RETAILER_PLATFORM.SHOPIFY,
    );
    return {
      authenticated: true,
      name: tokenData.displayName,
      roles: tokenData.roles,
      integrations: tokenData.integrations,
      retailerId: tokenData.mainRetailerId,
      retailerName: integration?.name,
      storeHost: integration?.storeHost,
    };
  })
  .handleAction(logoutAction, () => ({
    authenticated: false,
  }));

interface IRetailerTokenData {
  id: string;
  roles: ACCOUNT_ROLE[];
  displayName: string;
  integrations: Integration[];
  /**
   * will be removed when we'll aggregate data for all connected retailers
   * for now this is needed to reuse the shopify-retailer endpoints
   *  */
  mainRetailerId: string;
}
