export const getDirtyValues = <T extends object>(values: T, initialObject: T): T => {
  const data = { ...values };
  const keyValues = Object.keys(data) as Array<keyof T>;

  const dirtyValues = keyValues.filter((keyValue) => {
    if (typeof data[keyValue] === 'object' && data[keyValue] !== null) {
      return JSON.stringify(data[keyValue]) !== JSON.stringify(initialObject[keyValue]);
    }
    return data[keyValue] !== initialObject[keyValue];
  });

  keyValues.forEach((key) => {
    if (!dirtyValues.includes(key)) delete data[key];
  });

  return data;
};
