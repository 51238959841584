import { createReducer } from 'typesafe-actions';
import { CoreActions } from '../../actions';
import {
  setAuthenticatedAction,
  setHostAction,
  setStoreHostAction,
  setRolesAction,
  setIsLogged,
  setStoreActivityAction,
} from './auth.actions';

export enum ACCOUNT_ROLE {
  USER = 'USER',
  ADMIN = 'ADMIN',
}

interface IAuthReducer {
  accessToken: string | null;
  isLogged: boolean;
  inactiveStore: boolean;
  host: string | null;
  storeHost: string | null;
  roles: string[] | null;
}

const initialState: IAuthReducer = {
  accessToken: null,
  isLogged: false,
  inactiveStore: false,
  host: null,
  storeHost: null,
  roles: null,
};

export const authReducer = createReducer<IAuthReducer, CoreActions>(initialState)
  .handleAction(setAuthenticatedAction, (state, { payload: { accessToken } }) => ({
    ...state,
    accessToken,
  }))
  .handleAction(setIsLogged, (state, { payload: { isLogged } }) => ({
    ...state,
    isLogged,
  }))
  .handleAction(setRolesAction, (state, { payload: { roles } }) => ({
    ...state,
    roles,
  }))
  .handleAction(setHostAction, (state, { payload: { host } }) => ({
    ...state,
    host,
  }))
  .handleAction(setStoreHostAction, (state, { payload: { storeHost } }) => ({
    ...state,
    storeHost,
  }))
  .handleAction(setStoreActivityAction, (state, { payload: { inactiveStore } }) => ({
    ...state,
    inactiveStore: inactiveStore,
  }));
