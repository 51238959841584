import React, { useState } from 'react';
import { AppTextField } from '../../../../core/components/forms/text-field/text-field';
import { AppModal } from '../../../../core/components/overlays/modal/modal';
import { AppTextContainer } from '../../../../core/components/text-container/text-container';

type ContinueWithShopifyModalProps = {
  description?: string;
  onSuccess: (redirectUrl: string) => void;
  onCancel: () => void;
};

const domain = '.myshopify.com';

export const ContinueWithShopifyModal = ({
  description,
  onCancel,
  onSuccess,
}: ContinueWithShopifyModalProps) => {
  const [storeHost, setStoreHost] = useState('');
  const [error, setError] = useState('');

  return (
    <AppModal
      open
      title="Continue with Shopify"
      onClose={onCancel}
      primaryAction={{
        content: 'Continue',
        onAction: () => {
          if (!error) onSuccess(storeHost + domain);
        },
      }}
    >
      <AppTextContainer>
        <p>{description}</p>
      </AppTextContainer>
      <AppTextField
        label="Your Shopify store domain"
        name="shopify-domain"
        suffix={domain}
        value={storeHost}
        onChange={setStoreHost}
        autoComplete=""
        onBlur={() => {
          if (!storeHost.length) setError('Required');
          else setError('');
        }}
        error={error}
      />
    </AppModal>
  );
};
