export enum RETAILER_PLATFORM {
  SHOPIFY = 'Retailer: Shopify',
}

interface IIntegrationBase {
  platform: RETAILER_PLATFORM.SHOPIFY; // Other platforms will be added
}

interface IShopifyIntegration extends IIntegrationBase {
  platform: RETAILER_PLATFORM.SHOPIFY;
  retailerId: string;
  storeHost: string;
  name: string;
}

export type Integration = IShopifyIntegration;
