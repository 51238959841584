import React, { useCallback, useMemo, useState } from 'react';
import { Formik, Form } from 'formik';
import { AppChoiceList } from '../../../../../../core/components/choice-list/ChoiceList';
import { AppCard } from '../../../../../../core/components/structure/card/card';
import { SyncConflictVariantList } from './sync-conflict-product/sync-conflict-variant-list/sync-conflict-variant-list';
import { SyncConflictProduct } from './sync-conflict-product/sync-conflict-product';
import {
  IProductMatch,
  IProductConflict,
  IRetailerProductMatch,
  ISupplierProductMatch,
} from '../../../../../interfaces/IProductsSync';
import { MatchVariantList, VariantOwnerType } from '../../match-variant-list/match-variant-list';

type SyncConflictProps = {
  storeHost: string;
  resolveConflict: (conflict: { conflictId: string; match: IProductMatch }) => void;
  conflict: IProductConflict;
  disableAdding: boolean;
};

export const SyncConflict = ({
  storeHost,
  resolveConflict,
  conflict,
  disableAdding,
}: SyncConflictProps) => {
  const [selected, setSelected] = useState<string | undefined>(undefined);

  const handleConflictResolution = useCallback(
    (conflict: IProductConflict) => {
      if (!selected) return;
      const match = conflict.matches.find((m) => selected === m._id);
      if (!match) {
        console.error('An element is selected but not found in matches array');
        return;
      }
      resolveConflict({ conflictId: conflict._id, match });
    },
    [resolveConflict, selected],
  );

  const getVariantInputName = useCallback(
    (index: number, matchIndex: number, owner: VariantOwnerType) =>
      `matches[${matchIndex}].${owner}Product.variants[${index}].price`,
    [],
  );
  const getVariantSelectName = useCallback(
    (index: number, matchIndex: number) =>
      `matches[${matchIndex}].retailerProduct.variants[${index}].deleteOption`,
    [],
  );

  const renderConflictingProduct = useCallback(
    (product: IRetailerProductMatch | ISupplierProductMatch) => (
      <div className="conflicting-product">
        <SyncConflictProduct
          storeHost={storeHost}
          product={product}
          variants={<SyncConflictVariantList variants={product.variants} />}
        />
      </div>
    ),
    [storeHost],
  );

  const renderMatch = useCallback(
    (match: IProductMatch, matchIndex: number, productToDisplay: 'supplier' | 'retailer') => (
      <SyncConflictProduct
        storeHost={storeHost}
        product={productToDisplay === 'retailer' ? match.retailerProduct : match.supplierProduct}
        newVariantsCount={match.supplierProduct.variants.filter((v) => v.isMissing).length}
        obsoleteVariantsCount={match.retailerProduct.variants.filter((v) => v.isObsolete).length}
        variants={
          <MatchVariantList
            retailersVariants={match.retailerProduct.variants}
            suppliersVariants={match.supplierProduct.variants}
            getInputName={(sku, index, owner) => getVariantInputName(index, matchIndex, owner)}
            getSelectName={(sku, index) => getVariantSelectName(index, matchIndex)}
            disableInputs={disableAdding && selected !== match.id}
          />
        }
      />
    ),
    [storeHost, disableAdding, getVariantInputName, getVariantSelectName, selected],
  );

  const retailerBasedConflict = useMemo(
    () => [
      {
        title: "Pick the matching supplier's product",
        content: (
          <div className="product-option-list">
            <AppChoiceList
              name={conflict._id}
              choices={conflict.matches.map((m, i) => ({
                label: renderMatch(m, i, 'supplier'),
                value: m.id,
              }))}
              selected={selected ? [selected] : []}
              onChange={([selected]) => setSelected(selected)}
              title=""
              titleHidden
            />
          </div>
        ),
      },
      {
        title: 'for your product',
        content: renderConflictingProduct(conflict.matchOn),
      },
    ],
    [conflict, renderConflictingProduct, renderMatch, selected],
  );

  const supplierBasedConflict = useMemo(
    () => [
      {
        title: "Pick a match for this supplier's product",
        content: renderConflictingProduct(conflict.matchOn),
      },
      {
        title: 'from these matches',
        content: (
          <div className="product-option-list">
            <AppChoiceList
              name={conflict._id}
              choices={conflict.matches.map((m, i) => ({
                label: renderMatch(m, i, 'retailer'),
                value: m.id,
              }))}
              selected={selected ? [selected] : []}
              onChange={([selected]) => setSelected(selected)}
              title=""
              titleHidden
            />
          </div>
        ),
      },
    ],
    [conflict, renderConflictingProduct, renderMatch, selected],
  );

  return (
    <div className="conflict" key={conflict._id}>
      <Formik initialValues={conflict} onSubmit={handleConflictResolution}>
        {({ submitForm }) => (
          <Form name="conflict" id={conflict._id}>
            <AppCard
              primaryFooterAction={{
                content: 'Resolve',
                onAction: submitForm,
                disabled: !selected || disableAdding,
              }}
              sections={
                conflict.matchBase === 'retailer' ? retailerBasedConflict : supplierBasedConflict
              }
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};
