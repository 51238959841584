import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AppThumbnail } from '../../../../core/components/image-containers/thumbnail/Thumbnail';
import { AppIndexTableCell } from '../../../../core/components/index-table/index-table-cell';
import { AppIndexTableRow } from '../../../../core/components/index-table/index-table-row';
import { AppTextStyle } from '../../../../core/components/text/text-style/TextStyle';
import { TrackedQuantityLabelForVariant } from '../../../../core/components/tracked-quantity-label-for-variant/tracked-quantity-label-for-variant';
import { calculateMargin } from '../../../../core/helpers/calculate-margin.helper';
import { withCurrency } from '../../../../core/helpers/currency.helper';
import { validation } from '../../../../core/helpers/validations.helper';
import { ICurrency } from '../../../../core/interfaces/ICurrency';
import { getStoreHostSelector } from '../../../../core/redux/modules/auth/auth.selectors';
import { IVariantDetails } from '../../../interfaces/IProductDetails';
import { PriceSyncPreferencePopover } from './price-sync-preference-popover';
import './product-details.scss';

type VariantListRowProps = {
  shopifyProductId: number;
  unsavedVariant: IVariantDetails;
  variant: IVariantDetails;
  position: number;
  currency?: ICurrency;
  productId: string;
  setFieldValue: (field: string, value: any) => void;
  setUpdating: (value: boolean) => void;
};

export const VariantListRow = ({
  shopifyProductId,
  unsavedVariant,
  variant,
  position,
  productId,
  setFieldValue,
  setUpdating,
  currency = { isoCode: 'USD', symbol: '$' },
}: VariantListRowProps) => {
  const storeHost = useSelector(getStoreHostSelector);
  const { id, shopifyId, image, title, qty, sku, cost, MSRP, tracked, inventoryPolicy } = variant;
  const margin = useMemo(
    () =>
      cost &&
      unsavedVariant?.price &&
      validation.isPositiveNumber(unsavedVariant.price) === undefined
        ? calculateMargin(cost, unsavedVariant.price)
        : null,
    [cost, unsavedVariant.price],
  );

  return (
    <AppIndexTableRow id={id} key={id} position={position} selected={false}>
      <AppIndexTableCell>
        <AppThumbnail source={image} alt={title} size="large" />
      </AppIndexTableCell>
      <AppIndexTableCell>
        <div className="product-title">
          <AppTextStyle variation="strong">{title}</AppTextStyle>
          {shopifyId && (
            <>
              <span
                className="link-to-platform"
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(
                    `https://${storeHost}/admin/products/${shopifyProductId}/variants/${shopifyId}`,
                  );
                }}
              >
                <AppTextStyle variation="subdued">View on Shopify</AppTextStyle>
              </span>
            </>
          )}
        </div>
      </AppIndexTableCell>
      <AppIndexTableCell>{sku}</AppIndexTableCell>
      <AppIndexTableCell>
        {cost ? (
          withCurrency(cost, currency)
        ) : (
          <AppTextStyle variation="subdued">not set</AppTextStyle>
        )}
      </AppIndexTableCell>
      <AppIndexTableCell>
        {MSRP ? (
          withCurrency(MSRP, currency)
        ) : (
          <AppTextStyle variation="subdued">not set</AppTextStyle>
        )}
      </AppIndexTableCell>
      <AppIndexTableCell>
        <PriceSyncPreferencePopover
          unsavedVariant={unsavedVariant}
          variant={variant}
          position={position}
          setFieldValue={setFieldValue}
          setUpdating={setUpdating}
          productId={productId}
          prefix="variants."
          fixed
        />
      </AppIndexTableCell>
      <AppIndexTableCell>
        <AppTextStyle variation={margin && margin > 0 ? 'positive' : 'negative'}>
          {(margin !== null ? margin : '--') + '%'}
        </AppTextStyle>
      </AppIndexTableCell>
      <AppIndexTableCell>
        <TrackedQuantityLabelForVariant
          quantity={qty}
          tracked={tracked}
          inventoryPolicy={inventoryPolicy}
        />
      </AppIndexTableCell>
    </AppIndexTableRow>
  );
};
