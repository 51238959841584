import { Form, Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAxiosAuthorization } from '../../../../core/api/base.api';
import { AppButton } from '../../../../core/components/button/Button';
import { AppToast } from '../../../../core/components/feedback-indicators/toast/toast';
import { AppControlledTextField } from '../../../../core/components/forms/controlled-text-field/controlled-text-field';
import { AppLink } from '../../../../core/components/link/link';
import { AppCard } from '../../../../core/components/structure/card/card';
import { AppPage } from '../../../../core/components/structure/page/page';
import { AppTextContainer } from '../../../../core/components/text-container/text-container';
import { validation } from '../../../../core/helpers/validations.helper';
import { authApi } from '../../../api/auth.api';
import { setAuthenticatedAction } from '../../../redux/modules/auth/auth.actions';
import { ROUTES } from '../../../router/routes';
import { ContinueWithShopifyModal } from '../../containers/modals/continue-with-shopify-modal';
import './auth.scss';

export const SignInLayout = () => {
  const dispatch = useDispatch();
  const [loggingIn, setLoggingIn] = useState(false);
  const [openShopifyAuthModal, setOpenShopifyAuthModal] = useState(false);
  const [error, setError] = useState('');

  const handleSignInViaShopify = useCallback((storeHost: string) => {
    authApi
      .getShopifyAuthRedirectUrl(storeHost)
      .then(({ data }) => {
        if ('notFound' in data) {
          setError('Crowdship app was not installed on this shop');
        } else if ('url' in data) {
          window.open(data.url, '_self');
        }
      })
      .catch(() => {
        setError('Unexpected error');
      });
  }, []);

  const handleLogin = useCallback(
    (body: { email: string; password: string }) => {
      setLoggingIn(true);
      authApi
        .login(body)
        .then(({ data }) => {
          if ('error' in data) {
            setError('Cannot login with these credentials');
          } else {
            localStorage.setItem('token', data.token);
            setAxiosAuthorization(data.token);
            dispatch(setAuthenticatedAction(data.token));
          }
        })
        .catch((e) => {
          console.error(e);
          setError('Unexpected error. Please, try again later');
        })
        .finally(() => {
          setLoggingIn(false);
        });
    },
    [dispatch],
  );

  return (
    <AppPage>
      <div className="auth-page">
        <div className="small-card">
          <Formik
            initialValues={{ email: '', password: '' }}
            onSubmit={handleLogin}
            enableReinitialize
          >
            {({ submitForm }) => (
              <Form name={'sign-in'}>
                <AppCard
                  title="Sign In"
                  sectioned
                  primaryFooterAction={{
                    content: 'Sign In',
                    onAction: submitForm,
                    loading: loggingIn,
                  }}
                >
                  <div className="sign-in-via-platform">
                    <AppButton fullWidth onClick={() => setOpenShopifyAuthModal(true)}>
                      Continue with Shopify
                    </AppButton>
                  </div>
                  <div className="sign-in-separator">Or</div>
                  <AppControlledTextField
                    autoComplete=""
                    label="Email"
                    name="email"
                    type="email"
                    validate={validation.isEmail}
                  />
                  <AppControlledTextField
                    name="password"
                    autoComplete=""
                    label="Password"
                    type="password"
                    validate={validation.isNotEmpty}
                  />
                  <AppTextContainer>
                    New user? <AppLink url={ROUTES.SIGN_UP}>Create an account</AppLink>
                  </AppTextContainer>
                </AppCard>
              </Form>
            )}
          </Formik>
        </div>
        {openShopifyAuthModal && (
          <ContinueWithShopifyModal
            onCancel={() => setOpenShopifyAuthModal(false)}
            onSuccess={handleSignInViaShopify}
          />
        )}
        {error && <AppToast content={error} onDismiss={() => setError('')} error />}
      </div>
    </AppPage>
  );
};
